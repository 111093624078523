import { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations'; // Import the translations
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function Pricing({ textColor }) {
  const { language } = useLanguage();
  const currentTranslations = translations[language];
  const componentRef = useRef(null);
  const swiperRef = useRef(null); // Reference to Swiper instance
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (swiperRef.current) {
      if (isVisible) {
        swiperRef.current.autoplay.start(); // Start autoplay when visible
      } else {
        swiperRef.current.autoplay.stop(); // Stop autoplay when not visible
      }
    }
  }, [isVisible]);

  return (
    <div
      ref={componentRef}
      className="w-full min-h-[60em] relative isolate px-6 py-24 sm:py-32 lg:px-8 border-t-2 border-gray/50"
    >
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-base font-semibold text-[#336659]">
          {currentTranslations.Pricing1}
        </h2>
        <p className={`mt-2 text-5xl font-semibold tracking-tight ${textColor} sm:text-6xl`}>
          {currentTranslations.Pricing2}
        </p>
      </div>

      <div className="relative mt-16 lg:max-w-6xl mx-auto">
        <div className="flex justify-between">
          <div
            className="swiper-button-prev relative top-1/2 left-[-2rem] transform -translate-y-1/2 z-10 text-[#336659] text-3xl cursor-pointer"
            style={{ position: 'relative', color: '#336659' }}
          ></div>
          <div
            className="swiper-button-next relative top-1/2 right-[-2rem] transform -translate-y-1/2 z-10 text-[#336659] text-3xl cursor-pointer"
            style={{ position: 'relative', color: '#336659' }}
          ></div>
        </div>
        {/* Swiper Carousel */}
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          onSwiper={(swiper) => (swiperRef.current = swiper)} // Store Swiper instance
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          pagination={{
            el: '.swiper-pagination',
            type: 'progressbar',
          }}
          autoplay={{
            delay: 3000, // Delay in milliseconds (3 seconds)
            disableOnInteraction: true,
          }}
          speed={1000}
          spaceBetween={30}
          slidesPerView={1}
          breakpoints={{
            640: { slidesPerView: 1 },
            1024: { slidesPerView: 2 },
            1440: { slidesPerView: 3 },
          }}
          className="relative"
        >
          {currentTranslations.pricingPlans.map((plan, index) => (
            <SwiperSlide key={index}>
              <div className="bg-[#0c0e0d] rounded-3xl p-8 ring-1 ring-[#336659]/10 sm:p-10 flex flex-col h-[40rem]">
                <h3 className="text-white text-base font-semibold flex items-center gap-x-2 flex justify-between">
                  {plan.title}
                  {(plan.title === 'VIP Support' ||
                    plan.title === 'VIP сопровождение' ||
                    plan.title === 'VIP qo‘llab-quvvatlash') && (
                    <span className="text-red-500 line-through text-4xl">5300€</span>
                  )}
                </h3>
                <p className="mt-4 flex items-baseline gap-x-2">
                  <span className="text-5xl font-semibold tracking-tight text-white">
                    {plan.price}
                  </span>
                </p>
                <ul className="mt-8 space-y-3 text-sm text-gray-300">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex gap-x-3">
                      <span className="h-6 w-5 flex-none text-[#336659]">✔</span>
                      {feature}
                    </li>
                  ))}
                </ul>
                <a
                  onClick={() => {window.location.href = `/contacts#contacts-section`}}
                  className="mt-auto block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold bg-[#336659] text-white shadow-sm hover:bg-[#336659]/80"
                >
                  {plan.buttonLabel}
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

import { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations'; // Import the translations
import { MdEdit, MdPhone, MdSchool, MdInsertDriveFile, MdRocketLaunch } from 'react-icons/md';

export default function HowItWorks() {
  const [scrollProgress, setScrollProgress] = useState(15);
  const [isVisible, setIsVisible] = useState(false);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true); // Tracks if auto-scroll is active
  const containerRef = useRef(null);
  const componentRef = useRef(null);
  const animationFrameId = useRef(null);
  const autoScrollIntervalId = useRef(null);
  const manualScrollTimeoutId = useRef(null);

  const isMobile = () => window.matchMedia('(max-width: 768px)').matches;

  useEffect(() => {
    const handleScroll = () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      animationFrameId.current = requestAnimationFrame(() => {
        if (containerRef.current) {
          const container = containerRef.current;
          const maxScrollLeft = container.scrollWidth - container.clientWidth;
          setScrollProgress((container.scrollLeft / maxScrollLeft) * 100);
        }
      });

      // Stop auto-scroll on manual interaction
      if (isAutoScrolling) {
        setIsAutoScrolling(false);

        // Restart auto-scroll after a delay if no further manual interaction
        if (manualScrollTimeoutId.current) {
          clearTimeout(manualScrollTimeoutId.current);
        }
        manualScrollTimeoutId.current = setTimeout(() => {
          setIsAutoScrolling(true);
        }, 9000); // Restart auto-scroll after 5 seconds of inactivity
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isAutoScrolling]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isMobile() && isVisible && isAutoScrolling) {
      autoScrollIntervalId.current = setInterval(() => {
        if (containerRef.current) {
          const container = containerRef.current;
          const maxScrollLeft = container.scrollWidth - container.clientWidth;
          if (container.scrollLeft < maxScrollLeft) {
            container.scrollBy({ left: container.clientWidth, behavior: 'smooth' });
          } else {
            container.scrollTo({ left: 0, behavior: 'smooth' });
          }
        }
      }, 3000);
    } else {
      if (autoScrollIntervalId.current) {
        clearInterval(autoScrollIntervalId.current);
      }
    }

    return () => {
      if (autoScrollIntervalId.current) {
        clearInterval(autoScrollIntervalId.current);
      }
    };
  }, [isVisible, isAutoScrolling]);

  const { language } = useLanguage();

  // Get the current translations based on selected language
  const currentTranslations = translations[language];

  return (
    <div ref={componentRef} className="py-16 bg-white">
      <div className="max-w-screen-2xl mx-auto px-8 text-center">
        <h2 className="text-4xl font-extrabold mb-12 text-[#336659] font-playfair tracking-tight">
          {currentTranslations.HowItWorks1}
        </h2>

        {/* Steps Section - Slider on Mobile, Grid Layout on Larger Screens */}
        <div
          ref={containerRef}
          className="flex md:grid flex-row md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 lg:gap-8 overflow-x-auto md:overflow-visible px-4 md:px-8 snap-x snap-mandatory scroll-smooth"
        >
          {/* Step Cards */}
          {[
            {
              icon: <MdEdit className="w-16 h-16 text-black/70" />,
              bgColor: '#004643',
              textTitle: currentTranslations.HowItWorks2,
              textBody: currentTranslations.HowItWorks3,
            },
            {
              icon: <MdPhone className="w-16 h-16 text-black/70" />,
              bgColor: '#1B998B',
              textTitle: currentTranslations.HowItWorks4,
              textBody: currentTranslations.HowItWorks5,
            },
            {
              icon: <MdSchool className="w-16 h-16 text-black/70" />,
              bgColor: '#E63946',
              textTitle: currentTranslations.HowItWorks6,
              textBody: currentTranslations.HowItWorks7,
            },
            {
              icon: <MdInsertDriveFile className="w-16 h-16 text-black/70" />,
              bgColor: '#F4A261',
              textTitle: currentTranslations.HowItWorks8,
              textBody: currentTranslations.HowItWorks9,
            },
            {
              icon: <MdRocketLaunch className="w-16 h-16 text-black/70" />,
              bgColor: '#E9C46A',
              textTitle: currentTranslations.HowItWorks10,
              textBody: currentTranslations.HowItWorks11,
            },
          ].map((step, index) => (
            <div
              key={index}
              className="flex-shrink-0 md:flex-shrink-1 flex flex-col items-center text-center bg-gradient-to-br from-white to-gray-100 p-6 rounded-lg shadow-xl snap-center w-[85%] sm:w-[70%] md:w-full"
              style={{
                overflow: 'hidden',
                backgroundColor: step.bgColor,
              }}
            >
              {step.icon}
              <h3 className="text-xl font-semibold text-[#2C3E50] mb-2 mt-4 font-montserrat">
                {step.textTitle}
              </h3>
              <p className="text-sm text-gray-700 font-montserrat">
                {step.textBody}
              </p>
            </div>
          ))}
        </div>

        {/* Progress Bar */}
        <div className="mt-6 w-full h-3 bg-gray-300 rounded-full md:hidden relative">
          <div
            className="h-full bg-gradient-to-r from-[#004643] to-[#1B998B] rounded-full transition-transform duration-150 ease-out"
            style={{ width: `${scrollProgress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

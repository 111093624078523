import React from 'react';
import Footer from './Footer';
import { useLanguage } from '../LanguageContext'
import { translations } from '../translations' // Import the translations

const Visa = () => {
    const { language } = useLanguage();
    const currentTranslations = translations[language];
  return (
    <div className="bg-gradient-to-b from-[#0D1B1E] to-[#1A302B] text-[#E8DCC8] min-h-screen font-sans w-full flex flex-col pt-24">
      <div className="px-6 md:px-16 lg:px-24">
        {/* Hero Section */}
        <section className="text-center py-24 px-4 relative">
          <div className="relative w-full md:max-w-screen-xl mx-auto z-50">
            <div className="mb-6">
              <button
                onClick={() => window.history.back()}
                className="flex items-center space-x-2 text-gray-100 hover:text-gray-200 font-medium transition-colors duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
                <span>{currentTranslations.Back}</span>
              </button>
            </div>
          </div>
          <div className="max-w-screen-xl mx-auto">
          <div className="hidden md:block md:absolute inset-0 w-full h-full bg-[#1A302B] rounded-xl transform rotate-6 opacity-10"></div>

            <h2
            className="text-2xl md:text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold font-serif text-[#A8D9BB] mb-12 relative leading-tight break-words max-w-5xl mx-auto"
            >
            {currentTranslations.Visa1}
            </h2>


            <p className="text-2xl md:text-2xl md:text-3xl text-gray-300 max-w-4xl mx-auto relative">
              {currentTranslations.Visa2}
            </p>
          </div>
        </section>

        {/* Visa Assistance Steps */}
        <section className="max-w-screen-xl mx-auto py-24">
          <div className="text-center mb-20">
            <h3
            className="text-2xl md:text-3xl sm:text-4xl md:text-5xl font-bold text-[#A8D9BB] mb-6 leading-tight text-center break-words"
            >
            {currentTranslations.Visa3}
            </h3>

            <p className="text-lg text-gray-300 max-w-2xl mx-auto">
              {currentTranslations.Visa4}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
            <div className="bg-[#24393E] rounded-2xl p-10 shadow-2xl transform hover:-translate-y-3 transition-all duration-300">
              <div className="w-16 h-16 flex items-center justify-center bg-[#A8D9BB] text-black text-2xl font-bold rounded-full mb-8">
                1
              </div>
              <h3 className="text-2xl md:text-3xl font-semibold text-[#E8DCC8] mb-6">
                {currentTranslations.Visa5}
              </h3>
              <p className="text-lg text-gray-400">
                {currentTranslations.Visa6}
              </p>
            </div>

            <div className="bg-[#24393E] rounded-2xl p-10 shadow-2xl transform hover:-translate-y-3 transition-all duration-300">
              <div className="w-16 h-16 flex items-center justify-center bg-[#A8D9BB] text-black text-2xl font-bold rounded-full mb-8">
                2
              </div>
              <h3 className="text-2xl md:text-3xl font-semibold text-[#E8DCC8] mb-6">
                {currentTranslations.Visa7}
              </h3>
              <p className="text-lg text-gray-400">
                {currentTranslations.Visa8}
              </p>
            </div>

            <div className="bg-[#24393E] rounded-2xl p-10 shadow-2xl transform hover:-translate-y-3 transition-all duration-300">
              <div className="w-16 h-16 flex items-center justify-center bg-[#A8D9BB] text-black text-2xl font-bold rounded-full mb-8">
                3
              </div>
              <h3 className="text-2xl md:text-3xl font-semibold text-[#E8DCC8] mb-6">
                {currentTranslations.Visa9}
              </h3>
              <p className="text-lg text-gray-400">
                {currentTranslations.Visa10}
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Get Started Section */}
      <section className="bg-gradient-to-r from-[#1C3D33] to-[#24393E] py-24 px-4">
        <div className="max-w-screen-lg mx-auto text-center">
          <h2 className="text-2xl md:text-3xl md:text-5xl font-extrabold font-serif text-[#A8D9BB] mb-10">
            {currentTranslations.Visa11}
          </h2>
          <p className="text-2xl text-gray-300 max-w-3xl mx-auto mb-12">
            {currentTranslations.Visa12}
          </p>
          <a
            href="/Contacts"
            className="inline-block bg-[#A8D9BB] text-black px-12 py-6 text-xl font-bold rounded-full hover:bg-[#C6E2D8] transition-colors duration-300 hover:shadow-2xl hover:scale-110"
          >
            {currentTranslations.Visa13}
          </a>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Visa;


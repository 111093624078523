import { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations';

export default function Unis() {
  const { language } = useLanguage();
  const currentTranslations = translations[language];
  const [selectedTab, setSelectedTab] = useState('universities');

  return (
    <div className="bg-white text-gray-800 min-h-screen font-sans w-full pt-[8em] md:pt-[10em]">
      <div className="relative max-w-screen-xl mx-auto px-6">
        <div className="mb-6">
          <button
            onClick={() => window.history.back()}
            className="flex items-center space-x-2 text-gray-800 hover:text-gray-600 font-medium transition-colors duration-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
            <span>{currentTranslations.Back}</span>
          </button>
        </div>

        {/* Responsive button group */}
        <div className="flex flex-wrap justify-center gap-4 mb-8">
          <button
            onClick={() => setSelectedTab('universities')}
            className={`px-4 py-2 md:px-6 md:py-3 rounded-lg font-semibold transition-colors duration-300 ${
              selectedTab === 'universities' ? 'bg-gray-900 text-white' : 'bg-gray-300 text-gray-800'
            }`}
          >
            {currentTranslations.Unis1}
          </button>
          <button
            onClick={() => setSelectedTab('foundation')}
            className={`px-4 py-2 md:px-6 md:py-3 rounded-lg font-semibold transition-colors duration-300 ${
              selectedTab === 'foundation' ? 'bg-gray-900 text-white' : 'bg-gray-300 text-gray-800'
            }`}
          >
            {currentTranslations.Unis2}
          </button>
          <button
            onClick={() => setSelectedTab('languagecourse')}
            className={`px-4 py-2 md:px-6 md:py-3 rounded-lg font-semibold transition-colors duration-300 ${
              selectedTab === 'languagecourse' ? 'bg-gray-900 text-white' : 'bg-gray-300 text-gray-800'
            }`}
          >
            {currentTranslations.Unis0}
          </button>
        </div>

        {/* Content for universities */}
        {selectedTab === 'universities' && (
          <section className="max-w-screen-xl mx-auto py-16">
            {currentTranslations?.universities?.map((university, index) => {
              const imageSrc = `${process.env.PUBLIC_URL}/images/${university.image}`;
              return (
                <div
                  key={index}
                  className={`flex flex-col lg:flex-row items-center mb-16 ${
                    index % 2 === 0 ? 'lg:flex-row-reverse' : ''
                  } gap-12`}
                >
                  <div className="w-full lg:w-1/2 relative">
                    <img
                      src={imageSrc}
                      alt={university.name}
                      className="rounded-xl shadow-md w-full h-auto object-cover"
                    />
                  </div>

                  <div className="w-full lg:w-1/2">
                    <h3 className="text-4xl font-bold font-serif text-gray-900 mb-4">
                      {university.name}
                    </h3>
                    <p className="text-lg italic mb-4 text-gray-600">
                      📍 {university.location}
                    </p>
                    <h4 className="text-2xl font-semibold mb-2 text-gray-800">
                      {currentTranslations.Unis3}
                    </h4>
                    <p className="text-lg text-gray-600 mb-6">
                      {university.bestFor.join(', ')}
                    </p>
                    <h4 className="text-2xl font-semibold mb-2 text-gray-800">
                      {currentTranslations.Unis4}
                    </h4>
                    <ul className="list-disc pl-5 text-gray-600">
                      {university.features.map((feature, idx) => (
                        <li key={idx} className="mb-2">
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </section>
        )}

        {/* Content for language course */}
        {selectedTab === 'languagecourse' && (
          <section className="max-w-screen-xl mx-auto py-16">
            {currentTranslations?.languagecourses?.map((university, index) => {
              const imageSrc = `${process.env.PUBLIC_URL}/images/${university.image}`;
              return (
                <div
                  key={index}
                  className={`flex flex-col lg:flex-row items-center mb-16 ${
                    index % 2 === 0 ? 'lg:flex-row-reverse' : ''
                  } gap-12`}
                >
                  <div className="w-full lg:w-1/2 relative">
                    <img
                      src={imageSrc}
                      alt={university.name}
                      className="rounded-xl shadow-md w-full h-auto object-cover"
                    />
                  </div>

                  <div className="w-full lg:w-1/2">
                    <h3 className="text-4xl font-bold font-serif text-gray-900 mb-4">
                      {university.name}
                    </h3>
                    <p className="text-lg italic mb-4 text-gray-600">
                      📍 {university.location}
                    </p>
                    <h4 className="text-2xl font-semibold mb-2 text-gray-800">
                      {currentTranslations.Unis3}
                    </h4>
                    <p className="text-lg text-gray-600 mb-6">
                      {university.bestFor.join(', ')}
                    </p>
                    <h4 className="text-2xl font-semibold mb-2 text-gray-800">
                      {currentTranslations.Unis4}
                    </h4>
                    <ul className="list-disc pl-5 text-gray-600">
                      {university.features.map((feature, idx) => (
                        <li key={idx} className="mb-2">
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </section>
        )}

        {/* Content for foundation */}
        {selectedTab === 'foundation' && (
          <section className="max-w-screen-xl mx-auto py-16">
            {currentTranslations.foundationPrograms.map((program, index) => {
              const imageSrc = `${process.env.PUBLIC_URL}/images/${program.image}`;
              return (
                <div
                  key={index}
                  className={`flex flex-col lg:flex-row items-center mb-16 ${
                    index % 2 === 0 ? 'lg:flex-row-reverse' : ''
                  } gap-12`}
                >
                  <div className="w-full lg:w-1/2 relative">
                    <img
                      src={imageSrc}
                      alt={program.name}
                      className="rounded-xl shadow-md w-full h-auto object-cover"
                    />
                  </div>

                  <div className="w-full lg:w-1/2">
                    <h3 className="text-4xl font-bold font-serif text-gray-900 mb-4">
                      {program.name}
                    </h3>
                    <p className="text-lg italic mb-4 text-gray-600">
                      📍 {program.location}
                    </p>
                    <h4 className="text-2xl font-semibold mb-2 text-gray-800">
                      {currentTranslations.Unis3}
                    </h4>
                    <p className="text-lg text-gray-600 mb-6">
                      {program.bestFor.join(', ')}
                    </p>
                    <h4 className="text-2xl font-semibold mb-2 text-gray-800">
                      {currentTranslations.Unis4}
                    </h4>
                    <ul className="list-disc pl-5 text-gray-600">
                      {program.features.map((feature, idx) => (
                        <li key={idx} className="mb-2">
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </section>
        )}
      </div>


      <section className="bg-gray-100 py-16 text-center">
        <div className="max-w-screen-lg mx-auto">
          <h2 className="text-5xl font-bold font-serif text-gray-900 mb-6">
            {currentTranslations.Unis5}
          </h2>
          <p className="text-lg text-gray-700 mb-8">
            {currentTranslations.Unis6}
          </p>
          <a
            href="/Contacts"
            className="inline-block bg-gray-900 text-white px-8 py-4 text-lg font-semibold rounded-full hover:bg-gray-700 transition-colors duration-300"
          >
            {currentTranslations.Unis7}
          </a>
        </div>
      </section>
    </div>
  );
}

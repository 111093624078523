import React from 'react';
import Footer from './Footer';
import Pricing from "./Pricing";
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations';


const Admission = () => {

  
  const { language, setLanguage } = useLanguage()
  const currentTranslations = translations[language]
  
  const steps = [
    {
      id: 1,
      title: currentTranslations.Admission2,
      description: currentTranslations.Admission3,
    },
    {
      id: 2,
      title: currentTranslations.Admission4,
      description: currentTranslations.Admission5,
    },
    {
      id: 3,
      title: currentTranslations.Admission6,
      description: currentTranslations.Admission7,
    },
    {
      id: 4,
      title: currentTranslations.Admission8,
      description: currentTranslations.Admission9,
    },
  ];

  return (
    <div className="bg-gradient-to-b from-[#0D1B1E] to-[#1A302B] min-h-screen text-[#E8DCC8] font-sans w-full flex flex-col pt-[8em] md:pt-[10em]">
      <div className="max-w-6xl mx-auto relative px-2 z-10">

        <div className="relative w-full md:max-w-screen-xl mx-auto">
          <div className="mb-6">
            <button
              onClick={() => window.history.back()}
              className="flex items-center space-x-2 text-gray-100 hover:text-gray-200 font-medium transition-colors duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
              <span>{currentTranslations.Back}</span>
            </button>
          </div>
        </div>

        <h2 className="text-4xl md:text-6xl lg:text-7xl font-extrabold font-serif text-[#E8DCC8] mb-8 break-words max-w-full">
          {currentTranslations.About1}
        </h2>

        <div className="flex flex-col md:flex-row md:space-x-10">
          <div className="md:w-1/2 flex flex-col justify-evenly">
            <div className="border-l-4 border-[#A8D9BB] pl-10 relative h-full flex flex-col justify-evenly">
              {steps.map((step, index) => (
                <div key={step.id} className="flex items-start mb-8 flex-grow">
                  <div className="w-12 h-12 flex items-center justify-center bg-[#A8D9BB] text-black font-bold text-xl rounded-full shadow-lg px-2">
                    {step.id}
                  </div>
                  <div className="ml-8 bg-[#24393E] p-6 md:p-8 rounded-lg shadow-lg">
                    <h3 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-[#E8DCC8] mb-4">
                      {step.title}
                    </h3>
                    <p className="text-base md:text-lg lg:text-xl text-gray-300 leading-relaxed">
                      {step.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="md:w-1/2 mt-16 md:mt-0 flex items-stretch">
            <div className="bg-[#24393E] p-6 md:p-10 rounded-lg shadow-lg flex-grow flex flex-col justify-between">
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-[#A8D9BB] mb-6 text-center">
                {currentTranslations.Admission10}
              </h2>
              <p className="text-base md:text-lg lg:text-xl text-gray-300 leading-relaxed mb-6">
                {currentTranslations.Admission11}
              </p>
              <h3 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-[#A8D9BB] mb-4">
                {currentTranslations.Admission12}
              </h3>
              <ul className="list-disc list-inside text-base md:text-lg lg:text-xl text-gray-300 leading-relaxed">
                <li>{currentTranslations.Admission13}</li>
                <li>{currentTranslations.Admission14}</li>
                <li>{currentTranslations.Admission15}</li>
                <li>{currentTranslations.Admission16}</li>
                <li>{currentTranslations.Admission17}</li>
                <li>{currentTranslations.Admission18}</li>
              </ul>
              <p className="text-base md:text-lg lg:text-xl text-gray-300 leading-relaxed mt-6">
                {currentTranslations.Admission19}
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-[#0D1B1E] py-20 text-center mt-auto">
        <div className="max-w-screen-lg mx-auto">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-extrabold font-serif text-[#A8D9BB] mb-8">
            {currentTranslations.HeroSection1} 
          </h2>
          <a
            href="/Contacts"
            className="inline-block bg-[#A8D9BB] text-black px-10 py-5 text-lg md:text-xl lg:text-2xl font-bold rounded-full hover:bg-white transition-colors duration-300"
          >
            {currentTranslations.HeroSection3}
          </a>
        </div>
      </section>
      <Pricing textColor="text-[#A8D9BB]" />
      <Footer />
    </div>

  );
};

export default Admission;

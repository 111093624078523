import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
  AcademicCapIcon,
  GlobeAltIcon,
  PhoneIcon,
  MapPinIcon,
  LifebuoyIcon

} from '@heroicons/react/24/outline'

export const translations = {
  en: {
    services: 'Services',
    aboutUs: 'About Us',
    contact: 'Contact',
    reviews: 'Reviews',
    que: 'FAQ',
    que2: 'FAQ',
    products: [
      { name: 'University Admission', description: 'University admission (with or without scholarships)', href: '/Admission', icon: AcademicCapIcon },
      { name: 'Visa Assistance', description: 'Assistance with visa processing and document submission', href: '/Visa', icon: GlobeAltIcon },
    ],
    HeroSection3: [
      'Contact Us',
    ],
    HeroSection1: [
      'Open the doors to the best universities in Italy with us!',
    ],
    HeroSection2: [
      'We help students enter Italian universities, study for free and receive a scholarship of more than ',
      '7000€',
      'per year. Take the first step towards your dream with us!',
    ],
    Pricing1: [
      'Pricing',
    ],
    Pricing2: [
      'Choose a plan that suits your study and travel needs.',
    ],
    Pricing3: [
      'We offer flexible plans to support your journey, whether it’s university admission in Italy, visa assistance, or travel organization.',
    ],
    Pricing4: [
      'Basic',
    ],
    Pricing5: [
      'Perfect for students who need basic help with university applications and travel planning.',
    ],
    Pricing6: [
      'Assistance with university application',
    ],
    Pricing7: [
      'Visa assistance',
    ],
    Pricing8: [
      'Basic travel planning support',
    ],
    Pricing9: [
      'Order',
    ],
    Pricing10: [
      'Standard',
    ],
    Pricing11: [
      'Ideal for students needing comprehensive support with studies, visas, and travel logistics.',
    ],
    Pricing12: [
      'University application + essay review',
    ],
    Pricing13: [
      'Visa processing + document review',
    ],
    Pricing14: [
      'Detailed travel logistics planning',
    ],
    Pricing15: [
      'Premium',
    ],
    Pricing16: [
      'Our all-inclusive plan for students requiring complete support with applications, visas, and travel arrangements.',
    ],
    Pricing17: [
      'Comprehensive university application support + personal consultant',
    ],
    Pricing18: [
      'Expedited visa assistance + legal support',
    ],
    Pricing19: [
      'Comprehensive travel planning (flights, accommodation, etc.)',
    ],
    Stats2: [
      'Students admitted with our help',
    ],
    Stats1: [
      'Years of experience in education and tours',
    ],
    Stats3: [
      'Italian universities we’ve helped students get into',
    ],
    textSpecials: [
      'Special Features:',
    ],
    textBest: [
      'Best For:',
    ],
    HowItWorks1: [
      'How does it work?',
    ],
    HowItWorks2: [
      '1.Submit an application on the website',
    ],
    HowItWorks3: [
      'Fill out a simple form, and we’ll start the process right away!',
    ],
    HowItWorks4: [
      '2.We contact you and arrange a consultation',
    ],
    HowItWorks5: [
      'After submitting an application, our experts will contact you for a detailed consultation.',
    ],
    HowItWorks6: [
      '3.We select a university and program',
    ],
    HowItWorks7: [
      'We will help you choose the best university and suitable study program.',
    ],
    HowItWorks8: [
      '4.We prepare and submit your application',
    ],
    HowItWorks9: [
      'We’ll prepare all the necessary documents and submit your application to the chosen university.',
    ],
    HowItWorks10: [
      '5.You’re admitted and start a new chapter in your life!',
    ],
    HowItWorks11: [
      'Once you’re accepted, you embark on a new chapter in your academic life!',
    ],
    Services1: [
      'Our services — your comfort at every step',
    ],
    Services2: [
      'We simplify complex processes and help you focus on what’s important.',
    ],
    Services3: [
      'Consultation and university selection',
    ],
    Services4: [
      'We learn about your goals and select the best programs for you.',
    ],
    Services5: [
      'Document preparation',
    ],
    Services6: [
      'We assist with translations, legalization, and sending documents to universities.',
    ],
    Services7: [
      'Application submission and support',
    ],
    Services8: [
      'We submit applications, monitor the process, and provide consultations on all questions.',
    ],
    Services9: [
      'Relocation preparation',
    ],
    Services10: [
      'We assist with visas, accommodation, and adapting to a new country.',
    ],
    Services11: [
      '5 reasons to trust professionals',
    ],
    Services12: [
      'Quality assurance',
    ],
    Services13: [
      'We know all the nuances of applying to Italian universities.',
    ],
    Services14: [
      'Time-saving',
    ],
    Services15: [
      'We handle all the challenging preparation steps for you.',
    ],
    Services16: [
      'Personalized approach',
    ],
    Services17: [
      'Individual consultations and support at every step.',
    ],
    Services18: [
      'Partners in Italy',
    ],
    Services19: [
      'We work directly with universities.',
    ],
    Services20: [
      '24/7 Support',
    ],
    Services21: [
      'Prompt assistance and answers to your questions.',
    ],
    About1: [
      'Welcome to UniverMondo',
    ],
    About2: [
      'Your trusted education partner, helping CIS students achieve their dreams in Italy. From university admissions to travel and visa support, we provide a complete and personalized experience for students and their families.',
    ],
    About3: [
      'Our experience',
    ],
    About4: [
      '5 years of success, over 430 students helped, and admissions to more than 50 universities.',
    ],
    About5: [
      'University Admission',
    ],
    About6: [
      'We assist students at every stage of admission, from applications to scholarships, ensuring a smooth academic journey in Italy.',
    ],
    About7: [
      'Visa support',
    ],
    About8: [
      'Comprehensive support with student visas: from document submission to interview preparation, making the process as simple as possible.',
    ],
    About9: [
      'Personalized travel support',
    ],
    About10: [
      'From tickets to accommodation, we support students and their families every step of the way as they prepare for a new life in Italy.',
    ],
    About11: [
      'Our mission',
    ],
    About12: [
      'We help students achieve their dreams in one of the most culturally rich countries in the world. We make your educational journey effortless and exciting, providing personalized support at every stage.',
    ],
    About13: [
      'Contact Us',
    ],
    About14: [
      'Ready to take the next step? Contact us for personalized assistance and support.',
    ],
    Admission1: [
      'How will our collaboration work?',
    ],
    Admission2: [
      'Introduction',
    ],
    Admission3: [
      'First, we will meet for a free introductory consultation to explain everything and help you choose the right plan.',
    ],
    Admission4: [
      'Contract signing',
    ],
    Admission5: [
      'Next, we sign a contract where all the details of our collaboration are specified.',
    ],
    Admission6: [
      'Process start',
    ],
    Admission7: [
      'After the prepayment, we begin working on your admission to one or more universities.',
    ],
    Admission8: [
      'Visa and scholarship processing',
    ],
    Admission9: [
      'Depending on your plan, we’ll work on scholarship processing and visa documentation.',
    ],
    Admission10: [
      'HOW TO STUDY FOR FREE IN ITALIAN UNIVERSITIES',
    ],
    Admission11: [
      'Although studying in Italian public universities is cheaper than in many CIS universities, there is also an opportunity to get a grant and study for free. Every year, regional organizations throughout Italy provide scholarships to students as financial aid. They are intended for students whose income is below average by Italian standards. It’s easy to obtain, which is why most students from our countries study here thanks to these scholarships.',
    ],
    Admission12: [
      'What benefits does a student receive with this scholarship:',
    ],
    Admission13: [
      'Free education.',
    ],
    Admission14: [
      'Scholarship of 7,000–8,000€ per year.',
    ],
    Admission15: [
      'Help with housing.',
      ],
      Admission16: [
      'Hostel.',
      ],
    Admission17: [
      'Comprehensive dining at the cafeteria for free or at discounted prices.',
    ],
    Admission18: [
      'Discounted or free public transport.',
    ],
    Admission19: [
      'The amount and specific benefits depend on the region where the student will study. For example, in Tuscany, students receive a small cash payment, but accommodation and food are free.',
    ],
    Body1: [
      'Discover the best universities and programs',
    ],
    Body2: [
      'Explore the best universities and foundation programs tailored to your dreams. Learn everything you need about enrollment, programs, and more.',
    ],
    Body3: [
      'Explore Universities',
    ],
    Contacts1: ['Contacts'],
    Contacts2: ['Contact Us'],
    Contacts3: ['Phone (Italy): +39 351 3554047'],
    Contacts4: ['Phone (Uzbekistan): +9989(77)309-18-80'],
    Contacts5: ['Email:'],
    Contacts6: ['Moscow: Example St., Bldg. 1, Moscow, Russia'],
    Contacts7: ['Tashkent - Opening Soon!'],
    Contacts8: ['Thank you for your inquiry, we will contact you shortly!'],
    Contacts9: ['← Return to form'],
    Contacts10: ['Write to us'],
    Contacts11: ['Name'],
    Contacts12: [
      'Contact Information',
      'Telegram, WhatsApp, or Email',
      '(Optional) Briefly describe your situation: your level of English and Italian proficiency, the level of education and major you plan to apply for, your previous education. Would you like to apply for a scholarship? What country are you from?'
      ],
    Contacts13: ['Message'],
    Contacts14: ['Submitting...'],
    Contacts15: ['Submit'],
    Circle1: ['Thank you for reaching out, we will contact you shortly'],
    Circle2: ['Free Consultation'],
    Circle3: ['Name'],
    Circle4: ['Your name'],
    Circle5: ['Contact information'],
    Circle6: ['Telegram, WhatsApp, or Email'],
    Circle7: ['Submitting...'],
    Circle8: ['Submit'],
    Footer1: ['UniverMondo'],
    Footer2: ['Your partner for university admission in Italy'],
    Footer3: ['Contacts'],
    Footer4: ['Email:'],
    Footer5: ['Phone (Italy): +39 351 3554047'],
    Footer6: ['Phone (Uzbekistan): +9989(77)309-18-80'],
    Footer7: ['Moscow Address: Example St., Bldg. 1, Moscow, Russia'],
    Footer8: [',,Tashkent - Kukcha Darvoza Street, 581‘ Opening soon!'],
    Footer9: ['Quick Links'],
    Footer10: ['About Us'],
    Footer11: ['Services'],
    Footer12: ['Contact'],
    Footer13: ['Social Media'],
    Footer14: ['Join us on social media to stay updated on news and events:'],
    Footer15: ['All rights reserved {year} UniverMondo'],
    Footer16: ['User Agreement'],
    Footer17: ['Privacy Policy'],
    Footer18: ['Cookies Policy'],
    Footer19: ['Terms and Conditions'],
    ReviewsTitle: ['Student Reviews'],
    RP1: ['Show less'],
    RP2: ['Read more'],
    RTitle: ['Feedback from our students'],
    Reviews: [
      {
        name: 'Mukhutdinova Zamira',
        review: 'I am 18 years old, I am from Russia, and I enrolled in Italy after 11 years of schooling. I used the VIP Foundation services, including housing, from the company UniverMondo and am now in Pavia studying in the Foundation program. I am immensely grateful to this company because the amount of information and assistance I received from them would have been impossible to handle on my own. So many nuances, bureaucratic details, thousands of questions—at every step, the agents of this company literally guided me by the hand. They were always in touch, found answers to my questions, and most importantly, their employees are located in Italy and are ready to provide the most accurate and reliable information.',
        location: 'Kazan, Russia',
        image: "/images/reviews/review1.jpg",
        preview: "/images/reviews/preview1.png",
        video: `${process.env.PUBLIC_URL}/images/reviews/review1.MP4`,
        Rinsta: ['zamiraaa_am'],
      },
      {
        name: 'Khojakbar Jurakhonov',
        review: 'I am from the city of Tashkent, Uzbekistan. I am studying in Italy in the linguistics faculty. I would like to leave a review about the work of the UniverMondo team. I took a VIP package that included settling in, housing, and university admission. I am very satisfied with their work. We didn’t face any major problems upon arrival in Italy. I am very happy with the team’s work. I recommend them to everyone.',
        location: 'Tashkent, Uzbekistan',
        image: "/images/reviews/review2.jpg",
        preview: "/images/reviews/preview2.png",
         video: `${process.env.PUBLIC_URL}/images/reviews/review2.MP4`,
         Rinsta: ['hojackbar'],
      },
      {
        name: 'Khamidov Sardor',
        review: 'I am a simple student, reached great successes in just a few short years. One year ago, I turned to the UniverMondo agency, and they helped me take big steps forward, organizing everything perfectly. I had no difficulties at all. I can confidently say that UniverMondo is the number one agency.',
        location: 'Sirdarvo, Uzbekistan',
        image: "/images/reviews/review3.png",
        preview: "/images/reviews/preview3.png",
         video: `${process.env.PUBLIC_URL}/images/reviews/review3.mp4`,
         Rinsta: ['sardorkh1_'],
      },
      {
        name: 'Togʻbegiev Bahrulloh',
        review: 'I am studying at a language course in Milan. A year ago, I was looking for an agency to submit documents and organize training at a language school in Italy. The UniverMondo team helped me with this. Thanks to them, the process turned out to be very simple and clear. I can confidently recommend this agency to anyone planning to study at language schools in Italy.',
        location: 'Samarkand, Uzbekistan',
        image: "/images/reviews/preview4.png",
        preview: "/images/reviews/preview4.png",
         video: `${process.env.PUBLIC_URL}/images/reviews/review4.mp4`,
         Rinsta: ['sadullaevich.01'],
      },
      {
        name: 'Alok Singania',
        review: 'Hello, everyone. My name is Alok Kumar, and I’m from India. First of all, I want to sincerely thank UniverMondo, the agency that gave me the golden opportunity to study in Italy. Recently, I got admission into Industrial Automation Engineering in the Engineering Department, and I opted for their VIP services, which included support with housing and securing a scholarship. The agency treated me very well and handled all my documentation and the entire admission process seamlessly. They also guided me through finding accommodations, managing scholarship documentation, and ensuring everything was done perfectly. Their professionalism and support made the whole process stress-free for me. I am especially thankful to Imranondo, who personally ensured that I received my admission letter and took care of all the steps involved. His efforts were outstanding, and I couldn’t have done this without his help. Once again, I want to express my heartfelt gratitude to UniverMondo and Imranondo for making my dream of studying in Italy a reality. Thank you so much for this incredible opportunity!',
        location: 'Dehli, India',
        image: "/images/reviews/preview5.png",
        preview: "/images/reviews/preview5.png",
         video: `${process.env.PUBLIC_URL}/images/reviews/review5.MP4`,
         Rinsta: ['Singhania_alok001'],
      },
    ],
    universities: [
      {
        name: "Bocconi University",
        location: "Milan",
        bestFor: ["Economics", "Business Administration", "Finance"],
        features: [
          "One of Italy's leading universities for students aspiring to build careers in economics, business, and finance.",
          "Internationally recognized reputation in management, economics, and finance.",
          "English-language programs attract students from all over the world.",
        ],
        image: "bocconi.jpg",
      },
      {
        name: "Sapienza University of Rome",
        location: "Rome",
        bestFor: ["Medicine", "Engineering", "Archaeology"],
        features: [
          "The oldest and largest university in Italy, offering a wide range of educational programs.",
          "Renowned for medicine, engineering, archaeology, and the humanities.",
          "Strong ties with medical institutions, providing students with practical development opportunities.",
        ],
        image: "sapienza.jpg",
      },
      {
        name: "University of Pisa",
        location: "Pisa",
        bestFor: ["Astronomy", "Engineering", "Mathematics"],
        features: [
          "The university is known for its achievements in science, especially astronomy, mathematics, and engineering.",
          "Its scientists have made significant contributions to modern physics and mathematics.",
          "Programs focus on scientific research and high-tech industries.",
        ],
        image: "pisa.png",
      },
      {
        name: "University of Turin",
        location: "Turin",
        bestFor: ["Economics", "Law", "Biology"],
        features: [
          "The University of Turin is renowned for its strong programs in law, economics, and biology.",
          "Active collaboration with local industries and research institutions.",
          "Students have opportunities for internships at leading legal and economic organizations.",
        ],
        image: "turin.png",
      },
      {
        name: "University of Genova",
        location: "Genoa",
        bestFor: ["Engineering", "Marine Science", "Architecture"],
        features: [
          "A strong faculty in engineering and architecture with a focus on design and technology.",
          "The university is also known for its research in marine science and environmental technologies.",
          "Students gain practical skills through close ties with the maritime industry.",
        ],
        image: "genova.png",
      },
      {
        name: "Ca’ Foscari University of Venice",
        location: "Venice",
        bestFor: ["Economics", "Humanities", "Linguistics"],
        features: [
          "The university is renowned for its humanities and social sciences, including economics and linguistics.",
          "English-language programs attract students worldwide.",
          "Strong ties with cultural and educational institutions provide unique professional growth opportunities for students.",
        ],
        image: "venice.png",
      },
      {
        name: "University of Siena",
        location: "Siena",
        bestFor: ["Medicine", "Art History", "Economics"],
        features: [
          "The University of Siena offers strong programs in medicine and medical sciences.",
          "Siena, a cultural hub with a rich history, fosters research programs in art history.",
          "Renowned for its humanities and social sciences.",
        ],
        image: "siena.png",
      },
      {
        name: "University of Padua",
        location: "Padua",
        bestFor: ["Medicine", "Engineering", "Law"],
        features: [
          "One of the oldest universities in the world, Padua is known for its achievements in medicine and law.",
          "Strong programs in engineering disciplines, including robotics and biomedical engineering.",
          "Actively engaged in scientific research with excellent laboratory facilities.",
        ],
        image: "padua.png",
      },
      {
        name: "University of Florence",
        location: "Florence",
        bestFor: ["Arts", "Architecture", "History"],
        features: [
          "The University of Florence is recognized as a world leader in arts, art history, and architecture.",
          "Actively researches cultural heritage and architectural traditions.",
          "Numerous English-language programs are available for international students.",
        ],
        image: "florence.png",
      },
      {
        name: "University of Catania",
        location: "Catania",
        bestFor: ["Physics", "Engineering", "Economics"],
        features: [
          "The University of Catania is known for its scientific research in physics, particularly astrophysics and theoretical physics.",
          "Its engineering faculties actively develop new technologies and research in energy and mechanical engineering.",
          "Economics programs with a strong international focus.",
        ],
        image: "catania.jpg",
      },
      {
        name: "University of Tuscany",
        location: "Tuscany",
        bestFor: ["Agricultural Sciences", "Ecology", "Forestry"],
        features: [
          "The University of Tuscany actively develops research in sustainable development, ecology, and agricultural sciences.",
          "Forestry and natural resource programs address real-world environmental challenges in the region.",
          "Students participate in practical projects, gaining essential skills for environmental work.",
        ],
        image: "tuscany.png",
      },
      {
        name: "University of Perugia",
        location: "Perugia",
        bestFor: ["Sociology", "Political Science", "Law"],
        features: [
          "The University of Perugia is known for its strong programs in sociology, political science, and law.",
          "Renowned for its interdisciplinary approach to education and social science research projects.",
          "Excellent opportunities for internships and practices in international organizations.",
        ],
        image: "perugia.png",
      },
      {
        name: "University of Pavia",
        location: "Pavia",
        bestFor: ["Medicine", "Engineering", "Law", "Economics"],
        features: [
          "Renowned for its educational programs and high-level practical training. Students train in leading clinics and medical institutions.",
          "The university's engineering programs cover modern fields such as electrical, mechanical, and biomedical engineering, collaborating with high-tech companies.",
          "A small, cozy city with all necessities, located 30 minutes from Milan.",
        ],
        image: "pavia.png",
      },
    ],
    foundationPrograms: [
      {
        name: "Foundation Year in Rome (Roma)",
        location: "Rome",
        bestFor: ["Language Studies", "Bachelor's Preparation"],
        features: [
          "Università degli Studi di Roma La Sapienza — one of the largest universities in Italy, offering preparatory programs for international students who want to continue their education at the bachelor's or master's level.",
          "Foundation Year programs for both Italian language learners and students preparing to study in English within international programs."
        ],
        image: "rome.png"
      },
      {
        name: "Foundation Year in Florence (Florence)",
        location: "Florence",
        bestFor: ["Art", "Design", "Humanities"],
        features: [
          "Florence University of the Arts — intensive courses for students planning to study in the fields of art, design, business, and humanities.",
          "Instruction in both English and Italian. Florence is a cultural and historical center, offering unique opportunities for students, especially in art and the humanities."
        ],
        image: "florence2.png"
      },
      {
        name: "Foundation Year in Pavia (Pavia)",
        location: "Pavia",
        bestFor: ["Medicine", "Engineering", "Economics"],
        features: [
          "Università degli Studi di Pavia — one of the oldest universities in Italy, offering Foundation Year courses to help international students prepare for bachelor's studies.",
          "Preparatory courses focused on improving academic skills and language proficiency, as well as preparation for studying medicine, engineering, economics, and other disciplines."
        ],
        image: "pavia.png"
      },
      {
        name: "Foundation Year in Bologna (Bologna)",
        location: "Bologna",
        bestFor: ["Language Preparation", "Academic Preparation"],
        features: [
          "Università di Bologna — one of the oldest universities in the world, offering preparatory programs for international students.",
          "Programs focused on both language preparation and academic courses related to specific fields of study."
        ],
        image: "bologna.png"
      },
      {
        name: "Foundation Year in Venice (Venezia)",
        location: "Venice",
        bestFor: ["Economics", "Business", "Cultural Studies"],
        features: [
          "Università Ca’ Foscari Venezia — Foundation Year programs for students who want to prepare for studies in economics, business, arts, and cultural studies.",
          "Venice offers a unique atmosphere for students interested in studying art, culture, and history."
        ],
        image: "venice2.png"
      },
      {
        name: "Foundation Year in Turin (Torino)",
        location: "Turin",
        bestFor: ["Humanities", "Business", "Economics"],
        features: [
          "Università degli Studi di Torino — offers Foundation Year programs for international students, especially those planning to enroll in bachelor's programs in humanities, business, and economics.",
          "Turin is an industrial and academic hub with preparatory programs aimed at improving students' academic and language skills."
        ],
        image: "turin.png"
      },
      {
        name: "Foundation Year in Siena (Siena)",
        location: "Siena",
        bestFor: ["Humanities", "Social Sciences"],
        features: [
          "Università degli Studi di Siena — offers Foundation Year courses designed to prepare students for bachelor's programs in humanities and social sciences.",
          "Siena is a university with strong humanities traditions, where Foundation Year programs often focus on Italian culture and language studies."
        ],
        image: "siena2.png"
      },
      {
        name: "Foundation Year in Pisa (Pisa)",
        location: "Pisa",
        bestFor: ["Humanities", "Engineering"],
        features: [
          "Università di Pisa — offers Foundation Year programs for international students, particularly in the fields of humanities, social sciences, and engineering.",
          "Preparatory courses aimed at improving academic and language skills."
        ],
        image: "pisa2.png"
      },
      {
        name: "Foundation Year in Como (Como)",
        location: "Como",
        bestFor: ["Economics", "Law", "Social Sciences"],
        features: [
          "Università degli Studi dell’Insubria — the university in Como offers Foundation Year programs focused on economics, law, and social sciences.",
          "A more compact university but with high-quality programs for international students."
        ],
        image: "como.png"
      }
    ],
    languagecourses: [
      {
        name: "Scuola Leonardo da Vinci",
        location: "Milan, Rome, Florence, Turin",
        bestFor: [
          "Intensive Italian Courses",
          "Long-Term Italian Courses",
          "Preparation for Italian Language Certificates"
        ],
        features: [
          "Small class sizes",
          "Experienced native-speaking teachers",
          "Cultural activities and excursions",
          "Accommodation assistance",
          "Free Wi-Fi and study materials"
        ],
        image: "milan.png"
      },
      {
        name: "Dilit International House",
        location: "Rome, Italy",
        bestFor: [
          "Group Courses",
          "Individual Courses",
          "Combined Courses",
          "Academic Programmes",
          "Online Courses"
        ],
        features: [
          "Communicative teaching approach",
          "Experienced teachers",
          "Cultural activities",
          "Accommodation assistance",
          "Modern facilities with free Wi-Fi"
        ],
        image: "rome.png"
      }
    ],
    
    
    Unis0: ['Language courses'], 
    Unis1: ["Universities"],
    Unis2: ["Foundation Programs"],
    Unis3: ["Best For:"],
    Unis4: ["Features:"],
    Unis5: ["Contact Us"],
    Unis6: ["Ready to begin your educational journey in Italy? Contact us for consultation."],
    Unis7: ["Write to Us"],

    pricingPlans: [
      {
        title: 'University Admission Assistance',
        price: '1600€',
        features: [
          'Personal curator support for university admission',
          'Selection of universities, programs, and requirements',
          'Assistance with university documentation',
          'Help with notarization, translations, and legalization of documents',
          'Application submission to the university and processing of all documentation on our side',
          'Assistance with all enrollment processes',
          'Chat group with fellow applicants',
        ],
        buttonLabel: 'Purchase',
      },
      {
        title: 'Scholarship Assistance',
        price: '2600€',
        features: [
          'Personal curator support for obtaining a scholarship',
          'Help with scholarship documentation',
          'Assistance with bank documents and family status details',
          'Help with motivational and recommendation letters',
          'Help with notarization, translations, and legalization of documents',
          'Chat group with like-minded people',
        ],
        buttonLabel: 'Purchase',
      },
      {
        title: 'Full Visa Assistance',
        price: '1500€',
        features: [
          'Filling out the visa application form',
          'Booking visa interview (if needed)',
          'Preparation for the visa interview (mock interviews)',
          'Review and correction of all documents (applications, forms, translations, notarizations)',
        ],
        buttonLabel: 'Purchase',
      },
      {
        title: 'Foundation',
        price: '1500€',
        features: [
          'Selection of universities, programs, and requirements',
          'Assistance with document collection',
          'University application submission',
          'Assistance with all enrollment processes',
          'Chat group with fellow applicants',
          'Visa consulting',
          'Consultation on all bureaucratic matters in Italy',
        ],
        buttonLabel: 'Purchase',
      },
      {
        title: 'VIP Support',
        price: '4000€',
        features: [
          'Full support for university admission',
          'Full support for obtaining a scholarship',
          'Help with housing search',
          'Personal curator meeting at the airport',
          'Assistance with residence permit, insurance, tax code, and filling out any documentation',
          'Chat group with fellow applicants',
        ],
        buttonLabel: 'Purchase',
      },
      {
        title: 'Other Services',
        price: '',
        features: [
          'Consultation on admission. Audio/video call in any messenger with an experienced curator from our team lasting up to 1 hour (100€)',
          'Airport transfer (50€-200€)',
          'Curator support for submitting residence permit, opening a bank account (115€)',
          'Chat group with applicants (100€)',
          'Professional preparation of Motivation or Recommendation letters (190€)',
          'Full support with housing assistance (990€)',
          'Assistance with “GUARDIANSHIP” document when moving to Italy as a minor (550€)',
        ],
        buttonLabel: 'Purchase',
      },
    ],
    Pricing1: ['Our Services'], 
    Pricing2: ['Choose the right plan'],
    Visa1: ["Assistance in obtaining student and tourist visas"],
    Visa2: ["Comprehensive support for your student visa journey – from consultation to document submission, we make it effortless."],
    Visa3: ["How We Make Visa Processing Easy"],
    Visa4: ["We provide complete guidance, ensuring all documents are accurate and submitted on time."],
    Visa5: ["Initial Consultation"],
    Visa6: ["Start with a personalized consultation to assess your needs and guide you through the visa requirements."],
    Visa7: ["Document Preparation"],
    Visa8: ["Our team helps you gather, prepare, and verify all required documents to ensure compliance."],
    Visa9: ["Submission & Follow-Up"],
    Visa10: ["We handle document submission and provide follow-up support to keep you informed throughout the process."],
    Visa11: ["Ready to Start Your Journey?"],
    Visa12: ["Contact us today to make your dream of studying abroad a reality with expert visa assistance."],
    Visa13: ["Contact Us"],
    Back: ["Back"],
    Cookies1: ['Cookie Notice'],
    Cookies2: ['We use cookies to ensure that we give you the best experience on our website.'],
    Cookies3: ['Read cookies policies'],
    Cookies4: ['About Us'],
    Cookies5: ['Accept'],

    Info1: ['User Agreement'],
    Info2: ['Privacy Policy'],
    Info3: ['Cookies Policy'],
    Info4: ['Terms and Conditions'],
    Info5: [
      `User Agreement
        Effective Date: 06.12.2024

        Welcome to UniverMondo.com! By accessing or using our services, you agree to comply with the following User Agreement. Please read this document carefully before using our website or services.

        1. Services Provided
        UniverMondo offers the following services:

        Assistance with university applications in Italy.
        Visa application guidance and processing support.
        Travel arrangements and logistics.
        Personalized guides to help students navigate their new environment in Italy.
        All services are subject to the terms outlined in this agreement.

        2. User Information
        To use our services, you must fill out our contact form, which requires:

        Your name.
        A brief description of your situation (e.g., educational goals, travel plans).
        Your contact information (e.g., phone number or email).
        By submitting this information, you confirm that:

        The information provided is accurate and truthful.
        You are at least 18 years old or have parental/guardian consent to use our services.
        3. Personal Data Use
        We respect your privacy. All personal data submitted through our website will be handled according to our Privacy Policy. By using our services, you agree to the following:

        Your information will only be used to contact you and provide the requested services.
        We will not share your information with third parties, except as required to deliver our services (e.g., university or visa processing authorities).
        4. Service Limitations
        While we strive to provide accurate and timely assistance, you acknowledge that:

        UniverMondo acts as a facilitator and does not guarantee acceptance into universities, visa approvals, or specific outcomes.
        Any delays or issues arising from third-party entities (e.g., universities, visa offices, travel agencies) are beyond our control.
        Travel and visa services may be subject to external regulations, requirements, or unforeseen changes.
        5. User Responsibilities
        By using our services, you agree to:

        Provide all necessary documents and information promptly.
        Follow our guidelines and advice for university applications, visa processes, and travel arrangements.
        Be responsible for additional costs (e.g., application fees, travel expenses, visa fees).
        6. Fees and Payment
        Fees for our services will be communicated during the consultation process. Payment terms include:

        All payments must be made in full before the service is provided unless otherwise agreed.
        Refunds are not available once services have commenced, except in cases of service failure attributable to UniverMondo.
        7. Liability Disclaimer
        UniverMondo will not be held responsible for:

        Errors or delays caused by the user’s failure to provide accurate or timely information.
        Rejections or delays by universities, visa authorities, or other third parties.
        Losses or inconveniences caused by travel disruptions or unforeseen circumstances.
        Our liability is limited to the cost of the services provided by UniverMondo.

        8. Termination of Services
        UniverMondo reserves the right to terminate services if:

        The user provides false or misleading information.
        The user violates the terms of this agreement.
        In such cases, fees already paid are non-refundable.

        9. Changes to the Agreement
        We reserve the right to update or modify this User Agreement at any time. Changes will be posted on our website and take effect immediately. It is the user’s responsibility to review the agreement periodically.

        10. Governing Law
        This agreement is governed by the laws of Uzbekistan. Any disputes arising under this agreement shall be subject to the exclusive jurisdiction of the courts in Uzbekistan.

        11. Contact Information
        If you have any questions or concerns about this agreement, please contact us at:
        UniverMondo
        Email: univermondo@gmail.com
        Website: univermondo.com

        Would you like me to add or modify anything, such as specific refund policies or data protection details?`
    ],
    Info6: [
      `
      Privacy Policy
      Effective Date: 02.12.2024

      At UniverMondo.com, we value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and safeguard the information you provide when using our website and services.

      1. Information We Collect
      When you use our services, we may collect the following personal information:

      Contact Information: Your name, phone number, and email address.
      Situation Description: Details about your educational goals, travel plans, or other relevant information you provide in our contact form.
      Usage Data: Information about how you interact with our website, including your IP address, browser type, and pages visited.
      2. How We Use Your Information
      We use the information you provide for the following purposes:

      To communicate with you about our services and respond to your inquiries.
      To assist with university applications, visa processes, travel arrangements, and related services.
      To improve our website, services, and customer experience.
      To comply with legal and regulatory requirements.
      3. How We Share Your Information
      We do not sell, rent, or trade your personal information. However, we may share your data with trusted third parties in the following cases:

      Service Providers: To facilitate university applications, visa processes, or travel arrangements.
      Legal Obligations: If required by law, regulation, or legal process.
      We ensure that all third parties handling your data comply with strict privacy and security standards.

      4. Data Storage and Security
      We store your personal information securely using industry-standard encryption and security measures.

      Retention Period: We retain your data only as long as necessary to fulfill the purposes outlined in this policy or comply with legal obligations.
      Security Measures: We implement robust technical and organizational measures to prevent unauthorized access, disclosure, alteration, or destruction of your data.
      5. Your Rights
      You have the following rights regarding your personal data:

      Access: Request access to the information we hold about you.
      Correction: Request correction of inaccurate or incomplete data.
      Deletion: Request deletion of your personal data, subject to legal requirements.
      Objection: Object to the processing of your data for certain purposes.
      To exercise your rights, contact us at univermondo@gmail.com.

      6. Cookies and Tracking Technologies
      We use cookies and similar technologies to enhance your browsing experience and collect usage data.

      What Are Cookies? Cookies are small text files stored on your device that help us understand user behavior and improve our website.
      Managing Cookies: You can manage or disable cookies through your browser settings, but doing so may limit certain features of our website.
      7. Third-Party Links
      Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites. We recommend reviewing their privacy policies before sharing your data.

      8. Changes to This Privacy Policy
      We reserve the right to update or modify this Privacy Policy at any time. Changes will be posted on this page with the updated effective date. Please review this policy periodically to stay informed about how we protect your information.

      9. Contact Information
      If you have any questions or concerns about this Privacy Policy or your personal data, please contact us:
      UniverMondo
      Email: univermondo@gmail.com
      Website: univermondo.com
      `
    ],
    Info7: [
      `
      Cookies Policy
      Effective Date: 06.12.2024

      UniverMondo.com uses cookies and similar technologies to enhance your browsing experience, analyze website traffic, and improve our services. This Cookies Policy explains what cookies are, how we use them, and how you can manage them.

      1. What Are Cookies?
      Cookies are small text files placed on your device (computer, smartphone, tablet) when you visit a website. They help the website recognize your device and remember information about your visit, such as your preferences or actions.

      2. Types of Cookies We Use
      We use the following types of cookies on UniverMondo.com:

      a. Essential Cookies
      These cookies are necessary for the website to function properly. They enable core functionalities such as security, network management, and accessibility.

      Example: Maintaining your session while you navigate the website.
      b. Performance and Analytics Cookies
      These cookies collect information about how visitors use our website, such as pages visited and time spent on the site. The data helps us improve the website’s performance and user experience.

      Example: Google Analytics or similar tools.
      c. Functional Cookies
      These cookies allow the website to remember choices you make (e.g., language preferences) and provide enhanced features.

      Example: Storing your preferred language for future visits.
      d. Advertising Cookies
      We may use these cookies to deliver relevant advertisements based on your interests. They may also limit the number of times you see an ad and help measure the effectiveness of advertising campaigns.

      Example: Retargeting ads on social media platforms.
      3. How We Use Cookies
      We use cookies to:

      Ensure the website functions correctly and securely.
      Understand how visitors interact with our website.
      Remember your preferences and improve your user experience.
      Deliver personalized content and advertisements.
      4. Third-Party Cookies
      We may allow third-party service providers to place cookies on your device for analytics, advertising, or other purposes. These third parties have their own privacy and cookies policies, and we encourage you to review them.

      Examples of third-party cookies we may use include:

      Google Analytics for website traffic analysis.
      Social media platforms for sharing and advertising.
      5. Managing Cookies
      You can control and manage cookies in several ways:

      a. Browser Settings
      Most web browsers allow you to manage or disable cookies through their settings. Please note that disabling cookies may impact the functionality of our website.

      Example: Clearing cookies or blocking specific types of cookies.
      b. Opt-Out Tools
      You can opt out of certain third-party cookies by visiting their opt-out pages (e.g., Google Ads Settings).

      c. Cookie Banner
      When you first visit our website, you can accept or reject cookies via the cookie consent banner. Your preferences will be saved for future visits.

      6. Updates to This Cookies Policy
      We may update this Cookies Policy from time to time to reflect changes in technology, regulations, or our business practices. The latest version will always be available on this page, and the effective date will be updated accordingly.

      7. Contact Us
      If you have any questions or concerns about our use of cookies, please contact us:
      UniverMondo
      Email: univermondo@gmail.com
      Website: univermondo.com


      `
    ],
    Info8: [
      `
      Terms and Conditions
      Effective Date: 06.12.2024

      Welcome to UniverMondo.com! By accessing or using our website and services, you agree to these Terms and Conditions. Please read them carefully before using our site.

      1. Introduction
      UniverMondo.com is an agency that assists with:

      Applying to universities in Italy.
      Visa application guidance.
      Travel arrangements and logistics.
      Personalized city guides for students.
      These Terms and Conditions govern your use of our website and services. By using our services, you agree to comply with these terms.

      2. Eligibility
      To use our services, you must:

      Be at least 18 years old or have parental/guardian consent.
      Provide accurate and truthful information in all forms and communications.
      By using our website, you confirm that you meet these requirements.

      3. Services Provided
      UniverMondo acts as a facilitator to help students and travelers in Italy. While we aim to provide accurate and timely assistance, you acknowledge that:

      Acceptance to universities, visa approvals, and travel arrangements are not guaranteed.
      Any delays or issues caused by third parties (e.g., universities, consulates, travel agencies) are beyond our control.
      Additional costs (e.g., application fees, travel expenses, visa charges) are the user’s responsibility.
      4. User Obligations
      By using our services, you agree to:

      Provide all required documents and information promptly.
      Follow the instructions and guidelines we provide.
      Use our services only for lawful purposes.
      You are prohibited from:

      Misrepresenting your identity or intentions.
      Using our website or services to engage in fraudulent or illegal activities.
      5. Fees and Payments
      The costs of our services will be communicated to you during the consultation process.

      Payment must be made in full before services commence unless otherwise agreed.
      Refunds are not available once services have begun, except in cases of service failure attributable to UniverMondo.
      6. Limitation of Liability
      UniverMondo is not liable for:

      Rejections or delays by universities, visa authorities, or other third parties.
      Losses or inconveniences caused by travel disruptions or unforeseen circumstances.
      Errors or omissions in the information provided by users.
      Our total liability is limited to the amount paid for the specific service provided.

      7. Intellectual Property
      All content on UniverMondo.com, including text, graphics, logos, and design, is the property of UniverMondo and is protected by copyright and intellectual property laws.

      You may not reproduce, distribute, or use our content without prior written consent.
      8. Privacy Policy
      By using our services, you consent to the collection and use of your personal information as outlined in our Privacy Policy.

      9. Termination of Services
      We reserve the right to terminate or suspend access to our services if:

      You violate these Terms and Conditions.
      You provide false or incomplete information.
      In such cases, fees already paid are non-refundable.

      10. Third-Party Links
      Our website may contain links to external websites or services. UniverMondo is not responsible for the content, privacy practices, or terms of these third-party sites. Use these links at your own risk.

      11. Changes to Terms and Conditions
      We may update these Terms and Conditions periodically. Changes will be posted on this page with the updated effective date. It is your responsibility to review these terms regularly.

      12. Governing Law
      These Terms and Conditions are governed by the laws of Uzbekistan. Any disputes arising from these terms will be resolved in the courts of Uzbekistan.

      13. Contact Information
      If you have any questions or concerns about these Terms and Conditions, please contact us:
      UniverMondo
      Email: univermondo@gmail.com
      Website: univermondo.com


      `
    ],
    faq1: {
      question: ['What documents are needed to apply to an Italian university?'],
      answer: [
        'Requirements and document packages are tailored to each individual program. General documents include:',
        '1. Diploma with transcript',
        '2. Certificate with transcript',
        '3. Recommendation letter',
        '4. Resume',
        '5. Motivation letter',
        'If you need a specific set of documents and requirements for a particular program or specialty, you can schedule a consultation. At the end of the consultation, you will receive a file with all the necessary details.',
      ],
    },
    faq2: {
      question: ['I am from Kazakhstan/Tajikistan/Armenia... Can you help me?'],
      answer: ['Yes, we help students from all over the world to enroll and receive scholarships.'],
    },
    faq3: {
      question: ['How can I start working with you?'],
      answer: [
        'Submit a request for the tariff you are interested in on our website. Soon, we will contact you to schedule an introductory consultation to get to know each other and determine if we can help you. After the consultation, we will sign a contract outlining the obligations on both sides. After the advance payment, we start working on the procedure.',
      ],
    },
    faq4: {
      question: ['Which specialties can be studied for free?'],
      answer: [
        'In all public universities, regardless of the program, you can study for free. Some of the programs students study are:',
        '- Master Degree in Fashion Studies;',
        '- Master Degree in Anglo-American Studies;',
        '- Master Degree in Tourism Strategy, Cultural Strategy and Made in Italy;',
        '- Master Degree in Cognitive Science and Theory of Communication;',
        '- Bachelor Degree in Sustainable Building Engineering.',
        'And many other programs...',
      ],
    },
    faq5: {
      question: ['Can I apply to an Italian university after the 11th grade?'],
      answer: [
        'Unfortunately, to enroll in a bachelor’s program in Italy, the Italian system requires 12 years of academic education. One option is to enroll in a foundation year at an Italian university. Usually, it does not include discounts or grants, but upon completion, you can apply to an Italian university and study for free with a scholarship. We also assist in applying for the foundation program. Details can be found in the description of the "foundation" or "foundation + full VIP support" tariff.',
      ],
    },
    faq6: {
      question: ['Is it true that you can study completely free and with a scholarship?'],
      answer: [
        'Yes, it is true. The cost of education in Italy is calculated based on family income. If the income does not exceed the limits, the student is exempted from paying for education. If the student wins a regional scholarship, they are exempted from university fees and receive a scholarship of up to 7,000 euros.',
      ],
    },
    faq7: {
      question: ['What language is the education conducted in?'],
      answer: [
        'Education is conducted in English or Italian. The level of English required for admission is determined by each university and even each program individually. The average required level is B2.',
      ],
    },
    Stuff: [
      {
          job: 'Founder',
          name: 'Baxrom Bahtiyarovich',
          image: "/images/Founder.jpg",
          desc: 'When I was just starting, the admission process seemed incredibly difficult: choosing a university, preparing documents, and finding funding. But after going through it, I realized that my mission is to help others fulfill their dreams of studying abroad. That’s why I created UniverMondo - a project that helps students apply to leading universities worldwide, receive scholarships, and unlock new opportunities. We work individually with each student so that you know exactly what to do at every step and achieve your goals!',
      },
      {
          job: 'CEO',
          name: 'Anastasia Sergeyevna',
          image: "/images/SEO.jpg",
          desc: 'When I went through this journey myself, it was difficult to understand all the details: from choosing a university to preparing documents. But this experience gave me an understanding of how to avoid mistakes and achieve maximum results. Now I share my knowledge through UniverMondo - a service that helps applicants confidently apply to the world’s best universities. Together we will find a suitable program, prepare the documents, and increase your chances of getting a scholarship. Education abroad is not just a diploma; it’s a new chapter in life. Ready to take the first step? Sign up for a free consultation.',
      },
    ],
    
    
  },
  ru: {
    services: 'Услуги', // Услуги
    aboutUs: 'О нас',
    contact: 'Контакты',
    reviews: 'Отзывы',
    que: 'Вопросы и Ответы',
    que2: 'Часто задаваемые вопросы',
    products: [
      { name: 'Поступление в университет', description: 'Поступление в университет (со стипендией и без нее )', href: '/Admission', icon: AcademicCapIcon },
      { name: 'Помощь в оформлении визы', description: 'Помощь с оформлением визы и процессом подачи документов', href: '/Visa', icon: GlobeAltIcon },
    ],
    HeroSection3: [
      'Связаться с нами'
    ],
    HeroSection1: [
      'Откройте двери в лучшие университеты Италии вместе с нами!'
    ],
    HeroSection2: [
      'Мы помогаем студентам поступать в итальянские университеты, учиться бесплатно и получать стипендию более ',
      '7000€',
      ' в год. Сделайте первый шаг к своей мечте вместе с нами!',
    ],
    Pricing1: [
      'Цены'
    ],
    Pricing2: [
      'Выберите план, который соответствует вашим потребностям в учебе и путешествиях.'
    ],
    Pricing3: [
      'Мы предлагаем гибкие планы, чтобы поддержать ваше путешествие, будь то поступление в университет в Италии, помощь в оформлении визы или организация путешествий.'
    ],
    Pricing4: [
      'Начальный'
    ],
    Pricing5: [
      'Идеально для студентов, которым нужна базовая помощь в подаче заявлений в университет и планировании путешествий.'
    ],
    Pricing6: [
      'Помощь в подаче заявления в университет'
    ],
    Pricing7: [
      'Помощь в оформлении визы'
    ],
    Pricing8: [
      'Базовая поддержка в планировании путешествий'
    ],
    Pricing9: [
      'Заказать'
    ],
    Pricing10: [
      'Стандартный'
    ],
    Pricing11: [
      'Идеально для студентов, которым нужна всесторонняя поддержка в учебе, визах и логистике путешествий.'
    ],
    Pricing12: [
      'Заявка в университет + проверка эссе'
    ],
    Pricing13: [
      'Оформление визы + проверка документов'
    ],
    Pricing14: [
      'Подробное планирование логистики путешествий'
    ],
    Pricing15: [
      'Премиум'
    ],
    Pricing16: [
      'Наш всесторонний план для студентов, которым необходима полная поддержка в подаче заявлений, визовых вопросах и организации путешествий.'
    ],
    Pricing17: [
      'Полная поддержка подачи заявлений в университет + персональный консультант'
    ],
    Pricing18: [
      'Ускоренная помощь в визах + юридическая поддержка'
    ],
    Pricing19: [
      'Всеобъемлющее планирование путешествий (перелеты, проживание и т.д.)'
    ],
    Stats2: [
      'Cтудентов поступивших с нашей помощью'
    ],
    Stats1: [
      'Лет опыта команды в вопросах образования и туров'
    ],
    Stats3: [
      'Вузов Италии куда мы помогли поступить'
    ],
    textSpecials: [
      'Особенности:'
    ],
    textBest: [
      'Лучший по:'
    ],
    HowItWorks1: [
      'Как это работает?'
    ],
    HowItWorks2: [
      '1.Оставляете заявку на сайте'
    ],
    HowItWorks3: [
      'Заполните простую форму, и мы сразу начнем процесс!'
    ],
    HowItWorks4: [
      '2.Мы связываемся с вами и проводим консультацию'
    ],
    HowItWorks5: [
      'После заявки наши эксперты свяжутся с вами для детальной консультации.'
    ],
    HowItWorks6: [
      '3.Подбираем университет и программу'
    ],
    HowItWorks7: [
      'Мы поможем выбрать лучший университет и подходящую программу обучения.'
    ],
    HowItWorks8: [
      '4.Составляем и подаем заявку'
    ],
    HowItWorks9: [
      'Подготовим все необходимые документы и подадим заявку в выбранный университет.'
    ],
    HowItWorks10: [
      '5.Вы поступаете и начинаете новый этап своей жизни!'
    ],
    HowItWorks11: [
      'После принятия решения вы вступаете в новый этап своей академической жизни!'
    ],
    Services1: [
      'Наши услуги — ваш комфорт на каждом этапе'
    ],
    Services2: [
      'Мы упрощаем сложные процессы и помогаем вам сосредоточиться на главном.'
    ],
    Services3: [
      'Консультация и подбор университета'
    ],
    Services4: [
      'Узнаем ваши цели и подберем лучшие программы.'
    ],
    Services5: [
      'Подготовка документов'
    ],
    Services6: [
      'Помогаем с переводами, легализацией и отправкой документов в вузы.'
    ],
    Services7: [
      'Подача заявки и сопровождение'
    ],
    Services8: [
      'Оформляем заявки, следим за процессом и консультируем по всем вопросам.'
    ],
    Services9: [
      'Подготовка к переезду'
    ],
    Services10: [
      'Помогаем с визой, жильем и адаптацией в новой стране.'
    ],
    Services11: [
      '5 причин довериться профессионалам'
    ],
    Services12: [
      'Гарантия качества'
    ],
    Services13: [
      'Мы знаем все нюансы поступления в итальянские вузы.'
    ],
    Services14: [
      'Экономия времени'
    ],
    Services15: [
      'Берем на себя все сложные этапы подготовки.'
    ],
    Services16: [
      'Персональный подход'
    ],
    Services17: [
      'Индивидуальные консультации и помощь на каждом шаге.'
    ],
    Services18: [
      'Партнеры в Италии'
    ],
    Services19: [
      'Мы работаем с университетами напрямую.'
    ],
    Services20: [
      'Поддержка 24/7'
    ],
    Services21: [
      'Оперативная помощь и ответы на ваши вопросы.'
    ],
    About1: [
      'Добро пожаловать в UniverMondo',
    ],
    About2: [
      'Ваш надежный партнер в сфере образования, помогающий студентам из СНГ чтобы осуществить их мечты в Италии. От поступления в университет до поддержки в путешествиях и визах — мы предоставляем полный и персонализированный опыт для студентов и их семей.',
    ],
    About3: [
      'Наш опыт',
    ],
    About4: [
      '5 лет успеха, помощь более 430 студентам и поступление в более чем 50 университетов.',
    ],
    About5: [
      'Поступление в университет',
    ],
    About6: [
      'Мы помогаем студентам на всех этапах поступления, от подачи заявлений до получения стипендий, гарантируя гладкий академический путь в Италии.',
    ],
    About7: [
      'Визовая поддержка',
    ],
    About8: [
      'Полное сопровождение при оформлении студенческих виз: от подачи документов до подготовки к собеседованию, делая процесс максимально простым.',
    ],
    About9: [
      'Персональная поддержка в путешествиях',
    ],
    About10: [
      'От билетов до проживания — мы поддерживаем студентов и их семьи на каждом этапе подготовки к новой жизни в Италии.',
    ],
    About11: [
      'Наша миссия',
    ],
    About12: [
      'Мы помогаем студентам достигать их мечтаний в одной из самых культурно богатых стран мира. Мы делаем ваш образовательный путь легким и увлекательным, предоставляя персонализированную поддержку на каждом этапе.',
    ],
    About13: [
      'Свяжитесь с нами',
    ],
    About14: [
      'Готовы сделать следующий шаг? Свяжитесь с нами для получения персонализированной помощи и поддержки.',
    ],
    Admission1: [
      'Как будет проходить наше сотрудничество?',
    ],
    Admission2: [
      'Познакомимся',
    ],
    Admission3: [
      'Сначала мы познакомимся на бесплатной ознакомительной консультации, чтобы рассказать и помочь вам подобрать нужный тариф',
    ],
    Admission4: [
      'Заключим договор',
    ],
    Admission5: [
      'Далее мы заключим договор, где будут прописаны все детали нашего сотрудничества',
    ],
    Admission6: [
      'Начнем процесс',
    ],
    Admission7: [
      'После предоплаты мы начнём работать над процессом поступления в один или несколько вузов',
    ],
    Admission8: [
      'Работа над визой и стипендией',
    ],
    Admission9: [
      'В зависимости от вашего тарифа далее мы работаем над оформлением стипендии, документацией на визу',
    ],
    Admission10: [
      'КАК УЧИТЬСЯ В ИТАЛЬЯНСКОМ ВУЗЕ БЕСПЛАТНО',
    ],
    Admission11: [
      'Несмотря на то, что обучение в итальянских государственных ВУЗах дешевле, чем обучение во многих вузах стран СНГ, есть ещё возможность получить грант и учиться бесплатно с Каждый год региональные организации по всей Италии выдают стипендии студентам как материальную помощь. Она предназначена для студентов, чей доход по итальянским меркам ниже среднего. Ее легко получить, поэтому большинство ребят из наших стран учатся здесь благодаря этой стипендии.',
    ],
    Admission12: [
      'Какие льготы получает студент при получении этой стипендии:',
    ],
    Admission13: [
      'Бесплатное обучение.',
    ],
    Admission14: [
      'Стипендия в размере 7000-8000€ в год.',
    ],
    Admission15: [
      'Помощь с оплатой жилья.',
    ],
    Admission16: [
      'Общежитие.',
    ],
    Admission17: [
      'Комплексное питание в столовой бесплатно или по льготной цене.',
    ],
    Admission18: [
      'Скидка или бесплатный проезд на общественном транспорте.',
    ],
    Admission19: [
      'Сумма и конкретные льготы зависят от региона, где студент будет учиться. Например, в Тоскане студенты получают небольшую денежную выплату, но общежитие и питание бесплатно.',
    ],
    Body1: [
      'Откройте для себя лучшие университеты и программы',
    ],
    Body2: [
      'Исследуйте лучшие университеты и подготовительные программы, созданные для ваших мечтаний. Узнайте всё, что вам нужно о поступлении, программах и многом другом.',
    ],
    Body3: [
      'Список университетов',
    ],
    Contacts1: ['Контакты'],
    Contacts2: ['Свяжитесь с нами'],
    Contacts3: ['Телефон (Италия): +39 351 3554047'],
    Contacts4: ['Телефон (Узбекистан): +9989(77)309-18-80'],
    Contacts5: ['Почта:'],
    // Contacts6: ['Москва: Ул. Примерная, д. 1, Москва, Россия'],
    Contacts7: [' ,, Ташкент - Улица Кукча дарвоза , 581 ‘’ Скоро будет открытие !'],
    Contacts8: ['Спасибо за вашу заявку, мы свяжемся с вами в ближайшее время!'],
    Contacts9: ['← Вернуться к форме'],
    Contacts10: ['Напишите нам'],
    Contacts11: ['Имя'],
    Contacts12: [
      'Контактная информация',
      'Telegram, WhatsApp или Email',
      '(По Желанию) Кратко опишите вашу ситуацию: ваш уровень владения английским и итальянским языками, на какую ступень образования и специальность вы планируете поступать, какое у вас предыдущее образование. Хотели бы вы подаваться на стипендию? Из какой вы страны?'
    ],
    Contacts13: ['Сообщение'],
    Contacts14: ['Отправка...'],
    Contacts15: ['Отправить'],
    Circle1: ['Спасибо за обращение, мы свяжемся с вами в ближайшее время'],
    Circle2: ['Бесплатная Консультация'],
    Circle3: ['Имя'],
    Circle4: ['Ваше имя'],
    Circle5: ['Контактная информация'],
    Circle6: ['Telegram, WhatsApp или Email'],
    Circle7: ['Отправка...'],
    Circle8: ['Отправить'],
    Footer1: ['UniverMondo'],
    Footer2: ['Ваш партнер в поступлении в итальянские университеты'],
    Footer3: ['Контакты'],
    Footer4: ['Почта:'],
    Footer5: ['Телефон (Италия): +39 351 3554047'],
    Footer6: ['Телефон (Узбекистан): +9989(77)309-18-80'],
    Footer7: ['Адрес в Москве: Ул. Примерная, д. 1, Москва, Россия'],
    Footer8: [',,Ташкент - Улица Кукча дарвоза, 581‘’ Скоро будет открытие !'],
    Footer9: ['Быстрые ссылки'],
    Footer10: ['О нас'],
    Footer11: ['Услуги'],
    Footer12: ['Контакты'],
    Footer13: ['Социальные сети'],
    Footer14: ['Присоединяйтесь к нам в социальных сетях, чтобы быть в курсе всех новостей и событий:'],
    Footer15: ['Все права защищены {year} UniverMondo'],
    Footer16: ['Пользовательское соглашение'],
    Footer17: ['Политика конфиденциальности'],
    Footer18: ['Политика использования файлов cookie'],
    Footer19: ['Условия и положения'],
    RP1: ['Показать меньше'],
    RP2: ['Читать далее'],
    RTitle: ['Отзывы наших студентов'],
    ReviewsTitle: ['Отзывы наших студентов'],
    Reviews: [
      {
        name: 'Мухутдинова Замира',
        review: 'Мне 18 лет, я из России, поступила в Италию после 11 классов обучения. Я воспользовалась услугами VIP Foundation, включая жилье, от компании UniverMondo и сейчас нахожусь в Павии, обучаюсь на программе Foundation. Я безмерно благодарна этой компании, потому что количество информации и помощи, которые я получила от них, было бы невозможно осилить самой. Столько нюансов, бюрократических деталей, тысячи вопросов — на каждом этапе агенты этой компании буквально вели меня за руку. Они всегда были на связи, находили ответы на мои вопросы, и самое главное, их сотрудники находятся в Италии и готовы предоставить самую точную и достоверную информацию.',
        location: 'Казань, Россия',
        image: "/images/reviews/review1.jpg",
        preview: "/images/reviews/preview1.png",
        video: `${process.env.PUBLIC_URL}/images/reviews/review1.MP4`,
        Rinsta: ['zamiraaa_am'],
      },
      {
        name: 'Ходжакбар Джурахонов',
        review: 'Я из города Ташкент, Узбекистан. Учусь в Италии на факультете лингвистики. Хочу оставить отзыв о работе команды UniverMondo. Я взял VIP пакет, который включал заселение, жилье и поступление в университет. Я очень доволен их работой. Мы не столкнулись с какими-либо серьезными проблемами по приезду в Италию. Я очень доволен работой команды. Рекомендую всем.',
        location: 'Ташкент, Узбекистан',
        image: "/images/reviews/review2.jpg",
        preview: "/images/reviews/preview2.png",
        video: `${process.env.PUBLIC_URL}/images/reviews/review2.MP4`,
        Rinsta: ['hojackbar'],
      },
      {
        name: 'Хамидов Сардор',
        review: 'Я простой студент, добился больших успехов всего за несколько лет. Год назад я обратился в агентство UniverMondo, и они помогли мне сделать большие шаги вперед, организовав всё идеально. У меня не возникло никаких сложностей. Могу с уверенностью сказать, что UniverMondo — это агентство номер один.',
        location: 'Сырдарья, Узбекистан',
        image: "/images/reviews/review3.png",
        preview: "/images/reviews/preview3.png",
        video: `${process.env.PUBLIC_URL}/images/reviews/review3.mp4`,
        Rinsta: ['sardorkh1_'],
      },
      {
        name: 'Тогбегиев Бахруллох',
        review: 'Я учусь на языковых курсах в Милане. Год назад я искал агентство для подачи документов и организации обучения в языковой школе в Италии. В этом мне помогла команда UniverMondo. Благодаря им процесс оказался очень простым и понятным. Я уверенно могу порекомендовать это агентство всем, кто планирует обучение в языковых школах в Италии.',
        location: 'Самарканд, Узбекистан',
        image: "/images/reviews/preview4.png",
        preview: "/images/reviews/preview4.png",
         video: `${process.env.PUBLIC_URL}/images/reviews/review4.mp4`,
         Rinsta: ['sadullaevich.01'],
      },
      {
        name: 'Алок Сингания',
        review: 'Здравствуйте, меня зовут Алок Кумар, и я из Индии. Прежде всего, хочу искренне поблагодарить UniverMondo, агентство, которое предоставило мне золотую возможность учиться в Италии. Недавно я поступил на программу "Инженерия промышленных автоматизаций" в инженерном факультете и выбрал их VIP-услуги, которые включали помощь с жильем и получением стипендии. Агентство отнеслось ко мне очень внимательно и организовало всю мою документацию и процесс поступления безупречно. Они также помогли мне найти жилье, оформить документы на стипендию и убедились, что все выполнено идеально. Благодаря их профессионализму и поддержке весь процесс стал для меня бесстрессовым. Особую благодарность хочу выразить UniverMondo, который лично убедился, что я получил письмо о зачислении и позаботился обо всех этапах. Его усилия были выдающимися, и я бы не справился без его помощи. Еще раз хочу выразить искреннюю благодарность UniverMondo и UniverMondo за то, что они сделали мою мечту об обучении в Италии реальностью. Огромное спасибо за эту невероятную возможность!',
        location: 'Дели, Индия',
        image: "/images/reviews/preview5.png",
        preview: "/images/reviews/preview5.png",
        video: `${process.env.PUBLIC_URL}/images/reviews/review5.MP4`,
        Rinsta: ['Singhania_alok001'],
      }      
    ],
    

    universities: [
      {
        name: "Университет Боккони (Bocconi University)",
        location: "Милан",
        bestFor: ["Экономика", "Бизнес-администрирование", "Финансы"],
        features: [
          "Один из ведущих университетов Италии для студентов, стремящихся построить карьеру в экономике, бизнесе и финансах.",
          "Международно признанная репутация в сфере управления, экономики и финансов.",
          "Программы на английском языке, что привлекает студентов со всего мира.",
        ],
        image: "bocconi.jpg",
      },
      {
        name: "Университет Рима “Сапиенца” (Sapienza University of Rome)",
        location: "Рим",
        bestFor: ["Медицина", "Инженерия", "Археология"],
        features: [
          "Старейший и крупнейший университет в Италии, предлагает широкий спектр образовательных программ.",
          "Признание в области медицины, инженерии, археологии и гуманитарных наук.",
          "Прочные связи с медицинскими учреждениями, что дает студентам возможность развиваться в практическом контексте.",
        ],
        image: "sapienza.jpg",
      },
      {
        name: "Университет Пизы (University of Pisa)",
        location: "Пиза",
        bestFor: ["Астрономия", "Инженерия", "Математика"],
        features: [
          "Университет известен своими достижениями в области науки, особенно астрономии, математики и инженерии.",
          "Ученые университета сделали значительный вклад в развитие современной физики и математики.",
          "Программы, ориентированные на научные исследования и высокотехнологичные отрасли.",
        ],
        image: "pisa.png",
      },
      {
        name: "Университет Турина (University of Turin)",
        location: "Турин",
        bestFor: ["Экономика", "Юриспруденция", "Биология"],
        features: [
          "Университет Турина славится сильными программами по праву, экономике и биологии.",
          "Активное сотрудничество с местной промышленностью и научными учреждениями.",
          "Студенты имеют возможность проходить стажировки в ведущих юридических и экономических организациях.",
        ],
        image: "turin.png",
      },
      {
        name: "Университет Генуи (University of Genova)",
        location: "Генуя",
        bestFor: ["Инженерия", "Морская наука", "Архитектура"],
        features: [
          "Сильный факультет инженерии и архитектуры, с акцентом на проектирование и технологии.",
          "Университет также известен своими исследованиями в области морской науки и экологических технологий.",
          "Студенты получают практические навыки благодаря тесным связям с морской индустрией.",
        ],
        image: "genova.png",
      },
      {
        name: "Университет Венеции (Ca’ Foscari University of Venice)",
        location: "Венеция",
        bestFor: ["Экономика", "Гуманитарные науки", "Лингвистика"],
        features: [
          "Университет Венеции славится своими гуманитарными и социальными науками, включая экономику и лингвистику.",
          "Программы на английском языке, привлекающие студентов со всего мира.",
          "Прочные связи с культурными и образовательными учреждениями, что дает студентам уникальные возможности для профессионального роста.",
        ],
        image: "venice.png",
      },
      {
        name: "Университет Сиены (University of Siena)",
        location: "Сиена",
        bestFor: ["Медицина", "История искусств", "Экономика"],
        features: [
          "Университет Сиены имеет сильные программы по медицине и медицинским наукам.",
          "Сиена — это культурный центр с богатой историей, и университет активно развивает исследовательские программы по истории искусств.",
          "Признание в области гуманитарных наук и социальных наук.",
        ],
        image: "siena.png",
      },
      {
        name: "Университет Падуи (University of Padua)",
        location: "Падуя",
        bestFor: ["Медицина", "Инженерия", "Право"],
        features: [
          "Один из старейших университетов в мире, Падуа известна своими достижениями в медицине и праве.",
          "Сильные программы в инженерных дисциплинах, включая робототехнику и биомедицинскую инженерию.",
          "Университет активно участвует в научных исследованиях и имеет отличные лабораторные условия.",
        ],
        image: "padua.png",
      },
      {
        name: "Университет Флоренции (University of Florence)",
        location: "Флоренция",
        bestFor: ["Искусства", "Архитектура", "История"],
        features: [
          "Флорентийский университет признан мировым лидером в области искусства, истории искусств и архитектуры.",
          "Университет активно исследует культурное наследие и архитектурные традиции.",
          "Множество программ и курсов на английском языке для международных студентов.",
        ],
        image: "florence.png",
      },
      {
        name: "Университет Сицилии (University of Catania)",
        location: "Катания",
        bestFor: ["Физика", "Инженерия", "Экономика"],
        features: [
          "Университет Сицилии известен своими научными исследованиями в области физики, особенно в астрофизике и теоретической физике.",
          "Инженерные факультеты университета активно развивают новые технологии и исследования в области энергетики и машиностроения.",
          "Программы по экономике с сильным международным акцентом.",
        ],
        image: "catania.jpg",
      },
      {
        name: "Университет Тосканы “Санто-Стефано” (University of Tuscany)",
        location: "Тоскана",
        bestFor: ["Аграрные науки", "Экология", "Лесоводство"],
        features: [
          "Университет Тосканы активно развивает исследования в области устойчивого развития, экологии и аграрных наук.",
          "Программы по лесоводству и природным ресурсам связаны с реальными экологическими проблемами региона.",
          "Студенты участвуют в практических проектах, что дает им важные навыки для работы в сфере охраны окружающей среды.",
        ],
        image: "tuscany.png",
      },
      {
        name: "Университет Перуджи (University of Perugia)",
        location: "Перуджа",
        bestFor: ["Социология", "Политология", "Юриспруденция"],
        features: [
          "Университет Перуджи известен своими сильными программами в области социологии, политологии и права.",
          "Признание за междисциплинарный подход к обучению и исследовательским проектам в социальных науках.",
          "Отличные возможности для стажировок и практик в международных организациях.",
        ],
        image: "perugia.png",
      },
      {
        name: "Университет Павии (University of Pavia)",
        location: "Павия",
        bestFor: ["Медицина", "Инженерия", "Право", "Экономика"],
        features: [
          "Славится своими образовательными программами и высоким уровнем практики. Студенты проходят обучение в ведущих клиниках и медицинских учреждениях.",
          "Программы инженерии университета охватывают современные направления, такие как электротехника, машиностроение и биомедицинская инженерия. Сотрудничает с высокотехнологичными компаниями.",
          "Небольшой, уютный город в котором есть все что нужно, находится в 30 мин от Милана.",
        ],
        image: "pavia.png",
      },
    ],
    
    
    foundationPrograms: [
      {
        name: "Foundation Year в Риме (Roma)",
        location: "Рим",
        bestFor: ["Изучение языка", "Подготовка к бакалавриату"],
        features: [
          "Università degli Studi di Roma La Sapienza — один из крупнейших университетов Италии, предлагающий подготовительные программы для иностранных студентов, которые хотят продолжить обучение на магистратуре или бакалавриате.",
          "Foundation Year как для изучающих итальянский язык, так и для студентов, которые хотят подготовиться к учебе на английском языке в рамках международных программ."
        ],
        image: "rome.png",
      },
      {
        name: "Foundation Year во Флоренции (Florence)",
        location: "Флоренция",
        bestFor: ["Искусство", "Дизайн", "Гуманитарные науки"],
        features: [
          "Florence University of the Arts — интенсивные курсы для студентов, планирующих учёбу в области искусства, дизайна, бизнеса и гуманитарных наук.",
          "Обучение на английском и итальянском языках. Флоренция — культурный и исторический центр, что добавляет уникальные возможности для студентов, особенно в области искусства и гуманитарных наук."
        ],
        image: "florence2.png",
      },
      {
        name: "Foundation Year в Павии (Pavia)",
        location: "Павия",
        bestFor: ["Медицина", "Инженерия", "Экономика"],
        features: [
          "Università degli Studi di Pavia — один из старейших университетов Италии, предлагает Foundation Year курсы, которые помогут иностранным студентам подготовиться к учебе на бакалавриате.",
          "Подготовительные курсы, ориентированные на улучшение академических навыков и знаний языка, а также на подготовку к учебе в области медицины, инженерии, экономики и других дисциплин."
        ],
        image: "pavia.png",
      },
      {
        name: "Foundation Year в Болонье (Bologna)",
        location: "Болонья",
        bestFor: ["Языковая подготовка", "Академическая подготовка"],
        features: [
          "Università di Bologna — один из старейших университетов мира, предлагает программы подготовки для иностранных студентов.",
          "Программы ориентированы как на языковую подготовку, так и на академические курсы, связанные с конкретными направлениями учебы."
        ],
        image: "bologna.png",
      },
      {
        name: "Foundation Year в Венеции (Venezia)",
        location: "Венеция",
        bestFor: ["Экономика", "Бизнес", "Культурные исследования"],
        features: [
          "Università Ca’ Foscari Venezia — программы Foundation Year для студентов, которые хотят подготовиться к учебе в области экономики, бизнеса, искусств и культурных исследований.",
          "Венеция предлагает уникальную атмосферу для студентов, которые хотят изучать искусство, культуру и историю."
        ],
        image: "venice2.png",
      },
      {
        name: "Foundation Year в Турине (Torino)",
        location: "Турин",
        bestFor: ["Гуманитарные науки", "Бизнес", "Экономика"],
        features: [
          "Università degli Studi di Torino — университет предлагает Foundation Year для иностранных студентов, особенно тех, кто хочет учиться на программах бакалавриата в области гуманитарных наук, бизнеса и экономики.",
          "Турин — промышленный и университетский центр с программами подготовки, направленными на улучшение академической подготовки и языка для студентов."
        ],
        image: "turin.png",
      },
      {
        name: "Foundation Year в Сиене (Siena)",
        location: "Сиена",
        bestFor: ["Гуманитарные науки", "Социальные науки"],
        features: [
          "Università degli Studi di Siena — предлагает курсы Foundation Year, которые направлены на подготовку студентов к поступлению на бакалавриат по гуманитарным и социальным наукам.",
          "Сиена — университет с сильными гуманитарными традициями, где Foundation Year программы часто ориентированы на изучение итальянской культуры и языка."
        ],
        image: "siena2.png",
      },
      {
        name: "Foundation Year в Пизе (Pisa)",
        location: "Пиза",
        bestFor: ["Гуманитарные науки", "Инженерия"],
        features: [
          "Università di Pisa — этот университет предлагает программы Foundation Year для иностранных студентов, особенно в области гуманитарных и социальных наук, а также инженерии.",
          "Подготовительные курсы направлены на улучшение академических и языковых навыков."
        ],
        image: "pisa2.png",
      },
      {
        name: "Foundation Year в Комо (Como)",
        location: "Комо",
        bestFor: ["Экономика", "Право", "Социальные науки"],
        features: [
          "Università degli Studi dell’Insubria — университет в Комо предлагает Foundation Year программы, ориентированные на экономику, право и социальные науки.",
          "Это более компактный университет, но с высококачественными программами для иностранных студентов."
        ],
        image: "como.png",
      },
    ],
    languagecourses: [
      {
        name: "Scuola Leonardo da Vinci",
        location: "Милан, Рим, Флоренция, Турин",
        bestFor: [
          "Интенсивные курсы итальянского языка",
          "Долгосрочные курсы итальянского языка",
          "Подготовка к сертификатам итальянского языка"
        ],
        features: [
          "Маленькие группы",
          "Опытные преподаватели-носители языка",
          "Культурные мероприятия и экскурсии",
          "Помощь с размещением",
          "Бесплатный Wi-Fi и учебные материалы"
        ],
        image: "milan.png"
      },
      {
        name: "Dilit International House",
        location: "Рим, Италия",
        bestFor: [
          "Групповые курсы",
          "Индивидуальные курсы",
          "Комбинированные курсы",
          "Академические программы",
          "Онлайн-курсы"
        ],
        features: [
          "Коммуникативный подход к обучению",
          "Опытные преподаватели",
          "Культурные мероприятия",
          "Помощь с размещением",
          "Современные помещения с бесплатным Wi-Fi"
        ],
        image: "rome.png"
      }
    ],
    
    Unis0: ['Языковые курсы'], 
    Unis1: ['Университеты'], 
    Unis2: ['Foundation Programs'], 
    Unis3: ['Лучший по:'], 
    Unis4: ['Особенности:'], 
    Unis5: ['Свяжитесь с нами'], 
    Unis6: ['Готовы начать свой образовательный путь в Италии? Свяжитесь с нами для получения консультации.'], 
    Unis7: ['Написать нам'],

    pricingPlans: [
      {
        title: 'Помощь с поступлением в университет',
        price: '1600€',
        features: [
          'Личное сопровождение куратора с поступлением в университет',
          'Подбор университетов, программ и требований',
          'Помощь при сборе документации в университет',
          'Помощь при заверении, переводов, легализации документов',
          'Подача заявки в университет и обработка всех документаций с нашей стороны',
          'Помощь со всеми процессами для зачисления',
          'Чат в группу с поступающими',
        ],
        buttonLabel: 'Приобрести',
      },
      {
        title: 'Помощь со стипендией',
        price: '2600€',
        features: [
          'Личное сопровождение куратора с получением стипендии',
          'Помощь при сборе документации на стипендию',
          'Помощь при банковской документации и нюансах семейного состояния',
          'Помощь на мотивационное и рекомендационное письмо',
          'Помощь при заверении, переводов, легализации документов',
          'Чат в группу с единомышленниками',
        ],
        buttonLabel: 'Приобрести',
      },
      {
        title: 'Полное сопровождение для получения визы',
        price: '1500€',
        features: [
          'Заполнение визовой анкеты',
          'Запись на визовое интервью (если необходимо)',
          'Подготовка к визовому собеседованию (проведение пробных интервью)',
          'Проверка и корректировка всех документов (заявление, анкеты, переводы, заверения)',
        ],
        buttonLabel: 'Приобрести',
      },
      {
        title: 'Foundation',
        price: '1500€',
        features: [
          'Подбор университетов, программ и требований',
          'Помощь со сбором документов',
          'Подача заявок в университеты',
          'Помощь со всеми процессами для зачисления',
          'Чат в группу с поступающими',
          'Консультирование по вопросам визы',
          'Консультирование по всем бюрократическим вопросам Италии',
        ],
        buttonLabel: 'Приобрести',
      },
      {
        title: 'VIP сопровождение',
        price: '4000€',
        features: [
          'Полное сопровождение с поступлением в университет',
          'Полное сопровождение с получением стипендии',
          'Помощь с поиском жилья',
          'Личная встреча куратора в аэропорту',
          'Помощь с ВНЖ, страховкой, налогового кода и заполнением любой документации',
          'Чат в группу с поступающими',
        ],
        buttonLabel: 'Приобрести',
      },
      {
        title: 'Прочие услуги',
        price: '',
        features: [
          'Консультация по поступлению. Аудио/видеозвонок в любом мессенджере с опытным куратором из нашей команды длительностью до 1 час ( 100€)',
          'Трансфер из аэропорта (50€-200€)',
          'Сопровождение куратора на подачу ВНЖ, открытие банковского счёта (115€)',
          'Чат в группу поступающих (100€)',
          'Профессиональное оформление Motivation или Recommendation писем (190€)',
          'Полное сопровождение с помощью жилья (990€)',
          'Помощь с документом ,,ОПЕКУНСТВО’’ при переезде в Италию до совершеннолетия (550€)',
        ],
        buttonLabel: 'Приобрести',
      },
    ],
    Pricing1: ['Наши Услуги'], 
    Pricing2: ['Выберите подходящий план'], 
    Visa1: ["Помощь в получении студенческой и туристической визы"],
    Visa2: ["Полная поддержка на каждом этапе получения студенческой визы – от консультации до подачи документов, делаем этот процесс простым."],
    Visa3: ["Как мы облегчаем процесс получения визы"],
    Visa4: ["Мы предоставляем полное руководство, гарантируя, что все документы точны и поданы вовремя."],
    Visa5: ["Первичная консультация"],
    Visa6: ["Начните с персональной консультации, чтобы оценить ваши потребности и провести вас через требования для получения визы."],
    Visa7: ["Подготовка документов"],
    Visa8: ["Наша команда поможет вам собрать, подготовить и проверить все необходимые документы для соблюдения требований."],
    Visa9: ["Подача и сопровождение"],
    Visa10: ["Мы занимаемся подачей документов и предоставляем поддержку на всех этапах процесса, чтобы держать вас в курсе."],
    Visa11: ["Готовы начать ваше путешествие?"],
    Visa12: ["Свяжитесь с нами сегодня, чтобы сделать вашу мечту об обучении за границей реальностью с помощью нашей экспертизы в визовых вопросах."],
    Visa13: ["Связаться с нами"],
    Back: ["Назад"],
    Cookies1: ['Уведомление о куки'],
    Cookies2: ['Мы используем куки, чтобы обеспечить вам наилучший опыт на нашем сайте.'],
    Cookies3: ['Прочитать политику использования куки'],
    Cookies4: ['О нас'],
    Cookies5: ['Принять'],

    Info1: ['User Agreement'],
    Info2: ['Privacy Policy'],
    Info3: ['Cookies Policy'],
    Info4: ['Terms and Conditions'],
    Info5: [
    `Пользовательское соглашение
    Дата вступления в силу: 06.12.2024

    Добро пожаловать на UniverMondo.com! Получая доступ к нашим услугам или используя их, вы соглашаетесь соблюдать следующее Пользовательское соглашение. Пожалуйста, внимательно прочтите этот документ перед использованием нашего веб-сайта или услуг.

    1. Предоставляемые услуги
    UniverMondo предлагает следующие услуги:

    Помощь с поступлением в университеты Италии.
    Руководство по подаче заявлений на визу и поддержка в обработке.
    Организация поездок и логистика.
    Персонализированные гиды, которые помогут студентам сориентироваться в новой среде в Италии.
    Все услуги подчиняются условиям, изложенным в настоящем соглашении.

    2. Информация о пользователе
    Чтобы воспользоваться нашими услугами, вы должны заполнить нашу контактную форму, в которой требуется:

    Ваше имя.
    Краткое описание вашей ситуации (например, образовательные цели, планы поездок).
    Ваши контактные данные (например, номер телефона или адрес электронной почты).
    Отправляя эту информацию, вы подтверждаете, что:

    Предоставленная информация является точной и правдивой.
    Вам исполнилось 18 лет или у вас есть согласие родителя/опекуна на использование наших услуг.
    3. Использование персональных данных
    Мы уважаем вашу конфиденциальность. Все персональные данные, отправленные через наш веб-сайт, будут обрабатываться в соответствии с нашей Политикой конфиденциальности. Пользуясь нашими услугами, вы соглашаетесь со следующим:

    Ваша информация будет использоваться только для связи с вами и предоставления запрошенных услуг.
    Мы не будем передавать вашу информацию третьим лицам, за исключением случаев, когда это необходимо для предоставления наших услуг (например, университетам или органам по обработке виз).
    4. Ограничения обслуживания
    Хотя мы стремимся оказывать точную и своевременную помощь, вы признаете, что:

    UniverMondo выступает в качестве посредника и не гарантирует зачисление в университеты, одобрение виз или конкретные результаты.
    Любые задержки или проблемы, возникающие из-за сторонних организаций (например, университетов, визовых отделов, туристических агентств), находятся вне нашего контроля.
    Туристические и визовые услуги могут подчиняться внешним правилам, требованиям или непредвиденным изменениям.
    5. Обязанности пользователя
    Используя наши услуги, вы соглашаетесь:

    Своевременно предоставлять все необходимые документы и информацию.
    Следуйте нашим рекомендациям и советам по подаче заявлений в университет, визовым процессам и организации поездок.
    Нести ответственность за дополнительные расходы (например, регистрационные сборы, транспортные расходы, визовые сборы).
    6. Плата и оплата
    Плата за наши услуги будет сообщена в процессе консультации. Условия оплаты включают:

    Все платежи должны быть произведены в полном объеме до предоставления услуги, если иное не согласовано.
    Возврат средств невозможен после начала предоставления услуг, за исключением случаев сбоя обслуживания по вине UniverMondo.
    7. Отказ от ответственности
    UniverMondo не несет ответственности за:

    Ошибки или задержки, вызванные неспособностью пользователя предоставить точную или своевременную информацию.
    Отказы или задержки со стороны университетов, визовых служб или других третьих лиц.
    Убытки или неудобства, вызванные сбоями в поездке или непредвиденными обстоятельствами.
    Наша ответственность ограничивается стоимостью услуг, предоставляемых UniverMondo.

    8. Прекращение предоставления услуг
    UniverMondo оставляет за собой право прекратить предоставление услуг, если:

    Пользователь предоставляет ложную или вводящую в заблуждение информацию.
    Пользователь нарушает условия настоящего соглашения.
    В таких случаях уже уплаченные сборы не подлежат возврату.

    9. Изменения в Соглашении
    Мы оставляем за собой право обновлять или изменять настоящее Пользовательское соглашение в любое время. Изменения будут опубликованы на нашем веб-сайте и вступят в силу немедленно. Пользователь несет ответственность за периодический просмотр соглашения.

    10. Применимое право
    Настоящее соглашение регулируется законами Узбекистана. Любые споры, возникающие в связи с настоящим соглашением, подлежат исключительной юрисдикции судов в Узбекистана.

    11. Контактная информация
    Если у вас есть какие-либо вопросы или опасения по поводу этого соглашения, свяжитесь с нами по адресу:
    UniverMondo
    Электронная почта: univermondo@gmail.com
    Веб-сайт: univermondo.com

    Хотите, чтобы я что-то добавил или изменил, например, особые правила возврата или сведения о защите данных?`
    ],
    Info6: [
    `
    Политика конфиденциальности
    Дата вступления в силу: 02.12.2024

    В UniverMondo.com мы ценим вашу конфиденциальность и стремимся защищать ваши персональные данные. В этой Политике конфиденциальности объясняется, как мы собираем, используем и защищаем информацию, которую вы предоставляете при использовании нашего веб-сайта и услуг.

    1. Информация, которую мы собираем
    Когда вы пользуетесь нашими услугами, мы можем собирать следующую персональную информацию:

    Контактная информация: ваше имя, номер телефона и адрес электронной почты.
    Описание ситуации: сведения о ваших образовательных целях, планах поездок или другая соответствующая информация, которую вы предоставляете в нашей контактной форме.
    Данные об использовании: информация о том, как вы взаимодействуете с нашим веб-сайтом, включая ваш IP-адрес, тип браузера и посещенные страницы.
    2. Как мы используем вашу информацию
    Мы используем предоставленную вами информацию в следующих целях:

    Для общения с вами о наших услугах и ответа на ваши запросы.
    Для оказания помощи с подачей заявлений в университет, визовыми процессами, организацией поездок и сопутствующими услугами.
    Для улучшения нашего веб-сайта, услуг и обслуживания клиентов.
    Для соблюдения правовых и нормативных требований.
    3. Как мы делимся вашей информацией
    Мы не продаем, не сдаем в аренду и не обмениваем вашу личную информацию. Однако мы можем делиться вашими данными с доверенными третьими лицами в следующих случаях:

    Поставщики услуг: для упрощения подачи заявлений в университет, визовых процессов или организации поездок.
    Юридические обязательства: если этого требует закон, постановление или судебный процесс.
    Мы гарантируем, что все третьи лица, обрабатывающие ваши данные, соблюдают строгие стандарты конфиденциальности и безопасности.

    4. Хранение и безопасность данных
    Мы надежно храним вашу личную информацию, используя отраслевые стандартные меры шифрования и безопасности.

    Срок хранения: мы храним ваши данные только столько времени, сколько необходимо для достижения целей, изложенных в настоящей политике, или соблюдения юридических обязательств.
    Меры безопасности: мы применяем надежные технические и организационные меры для предотвращения несанкционированного доступа, раскрытия, изменения или уничтожения ваших данных.
    5. Ваши права
    У вас есть следующие права в отношении ваших персональных данных:

    Доступ: запросить доступ к информации о вас, которую мы храним.
    Исправление: запросить исправление неточных или неполных данных.
    Удаление: запросить удаление ваших персональных данных в соответствии с требованиями законодательства.
    Возражение: возражайте против обработки ваших данных в определенных целях.
    Чтобы воспользоваться своими правами, свяжитесь с нами по адресу univermondo@gmail.com.

    6. Файлы cookie и технологии отслеживания
    Мы используем файлы cookie и аналогичные технологии для улучшения вашего опыта просмотра и сбора данных об использовании.

    Что такое файлы cookie? Файлы cookie — это небольшие текстовые файлы, хранящиеся на вашем устройстве, которые помогают нам понимать поведение пользователей и улучшать наш веб-сайт.

    Управление файлами cookie: вы можете управлять файлами cookie или отключать их через настройки браузера, но это может ограничить некоторые функции нашего веб-сайта.

    7. Ссылки на сторонние веб-сайты
    Наш веб-сайт может содержать ссылки на сторонние веб-сайты или службы. Мы не несем ответственности за политику конфиденциальности или содержание этих внешних сайтов. Мы рекомендуем ознакомиться с их политикой конфиденциальности перед тем, как делиться своими данными.

    8. Изменения в настоящей Политике конфиденциальности
    Мы оставляем за собой право обновлять или изменять настоящую Политику конфиденциальности в любое время. Изменения будут опубликованы на этой странице с обновленной датой вступления в силу. Пожалуйста, периодически просматривайте эту политику, чтобы быть в курсе того, как мы защищаем вашу информацию.

    9. Контактная информация
    Если у вас есть какие-либо вопросы или опасения относительно этой Политики конфиденциальности или ваших персональных данных, свяжитесь с нами:
    UniverMondo
    Электронная почта: univermondo@gmail.com
    Веб-сайт: univermondo.com
    `
    ],
    Info7: [
    `
    Политика использования файлов cookie
    Дата вступления в силу: 06.12.2024

    UniverMondo.com использует файлы cookie и аналогичные технологии для улучшения вашего опыта просмотра, анализа трафика веб-сайта и улучшения наших услуг. В этой Политике использования файлов cookie объясняется, что такое файлы cookie, как мы их используем и как вы можете ими управлять.

    1. Что такое файлы cookie?
    Файлы cookie — это небольшие текстовые файлы, которые размещаются на вашем устройстве (компьютере, смартфоне, планшете) при посещении веб-сайта. Они помогают веб-сайту распознавать ваше устройство и запоминать информацию о вашем посещении, например ваши предпочтения или действия.

    2. Типы файлов cookie, которые мы используем
    Мы используем следующие типы файлов cookie на UniverMondo.com:

    a. Основные файлы cookie
    Эти файлы cookie необходимы для правильной работы веб-сайта. Они обеспечивают основные функции, такие как безопасность, управление сетью и доступность.

    Пример: сохранение сеанса во время навигации по веб-сайту.
    b. Файлы cookie производительности и аналитики
    Эти файлы cookie собирают информацию о том, как посетители используют наш веб-сайт, например, посещенные страницы и время, проведенное на сайте. Эти данные помогают нам улучшить производительность веб-сайта и пользовательский опыт.

    Пример: Google Analytics или аналогичные инструменты.
    c. Функциональные файлы cookie
    Эти файлы cookie позволяют веб-сайту запоминать сделанный вами выбор (например, языковые предпочтения) и предоставлять расширенные функции.

    Пример: сохранение предпочитаемого вами языка для будущих посещений.
    d. Рекламные файлы cookie
    Мы можем использовать эти файлы cookie для показа релевантной рекламы на основе ваших интересов. Они также могут ограничивать количество показов рекламы и помогать оценивать эффективность рекламных кампаний.

    Пример: ретаргетинг рекламы на платформах социальных сетей.
    3. Как мы используем файлы cookie
    Мы используем файлы cookie, чтобы:

    Обеспечить правильную и безопасную работу веб-сайта.

    Понять, как посетители взаимодействуют с нашим веб-сайтом.
    Запомнить ваши предпочтения и улучшить пользовательский опыт.
    Доставлять персонализированный контент и рекламу.
    4. Сторонние файлы cookie
    Мы можем разрешить сторонним поставщикам услуг размещать файлы cookie на вашем устройстве для аналитики, рекламы или других целей. У этих третьих лиц есть собственные политики конфиденциальности и использования файлов cookie, и мы рекомендуем вам ознакомиться с ними.

    Примеры сторонних файлов cookie, которые мы можем использовать:

    Google Analytics для анализа трафика веб-сайта.
    Платформы социальных сетей для обмена и рекламы.

    5. Управление файлами cookie
    Вы можете контролировать и управлять файлами cookie несколькими способами:

    a. Настройки браузера
    Большинство веб-браузеров позволяют вам управлять файлами cookie или отключать их через свои настройки. Обратите внимание, что отключение файлов cookie может повлиять на функциональность нашего веб-сайта.

    Пример: очистка файлов cookie или блокировка определенных типов файлов cookie.

    b. Инструменты отказа
    Вы можете отказаться от определенных сторонних файлов cookie, посетив их страницы отказа (например, настройки Google Ads).

    c. Баннер файлов cookie
    При первом посещении нашего веб-сайта вы можете принять или отклонить файлы cookie с помощью баннера согласия на использование файлов cookie. Ваши настройки будут сохранены для будущих посещений.

    6. Обновления этой политики в отношении файлов cookie
    Мы можем время от времени обновлять эту политику в отношении файлов cookie, чтобы отражать изменения в технологиях, правилах или нашей деловой практике. Последняя версия всегда будет доступна на этой странице, а дата вступления в силу будет обновляться соответствующим образом.

    7. Свяжитесь с нами
    Если у вас есть какие-либо вопросы или опасения по поводу использования нами файлов cookie, свяжитесь с нами:
    UniverMondo
    Электронная почта: univermondo@gmail.com
    Веб-сайт: univermondo.com

    `
    ],
    Info8: [
    `
    Условия
    Дата вступления в силу: 06.12.2024

    Добро пожаловать на UniverMondo.com! Заходя на наш сайт и используя его, вы соглашаетесь с настоящими Условиями. Пожалуйста, внимательно прочтите их перед использованием нашего сайта.

    1. Введение
    UniverMondo.com — это агентство, которое помогает с:

    Поступлением в университеты Италии.
    Руководством по подаче заявлений на получение визы.
    Организацией поездок и логистикой.
    Персонализированными путеводителями по городу для студентов.
    Эти Условия регулируют использование вами нашего сайта и услуг. Пользуясь нашими услугами, вы соглашаетесь соблюдать эти условия.

    2. Право на участие
    Чтобы пользоваться нашими услугами, вы должны:

    Быть не моложе 18 лет или иметь согласие родителя/опекуна.
    Предоставлять точную и правдивую информацию во всех формах и сообщениях.
    Пользуясь нашим сайтом, вы подтверждаете, что соответствуете этим требованиям.

    3. Предоставляемые услуги
    UniverMondo выступает в качестве посредника, помогающего студентам и путешественникам в Италии. Хотя мы стремимся оказывать точную и своевременную помощь, вы признаете, что:

    Зачисление в университеты, одобрение виз и организация поездок не гарантируются.
    Любые задержки или проблемы, вызванные третьими лицами (например, университетами, консульствами, туристическими агентствами), находятся вне нашего контроля.
    Дополнительные расходы (например, регистрационные сборы, транспортные расходы, визовые сборы) являются ответственностью пользователя.
    4. Обязанности пользователя
    Используя наши услуги, вы соглашаетесь:

    Предоставлять все необходимые документы и информацию незамедлительно.
    Следовать предоставленным нами инструкциям и рекомендациям.
    Использовать наши услуги только в законных целях.
    Вам запрещено:

    Искажать свою личность или намерения.
    Использовать наш веб-сайт или услуги для участия в мошеннической или незаконной деятельности.
    5. Платежи и платежи
    Стоимость наших услуг будет сообщена вам в процессе консультации.

    Оплата должна быть произведена в полном объеме до начала оказания услуг, если иное не согласовано.
    Возврат средств невозможен после начала оказания услуг, за исключением случаев сбоя обслуживания по вине UniverMondo.
    6. Ограничение ответственности
    UniverMondo не несет ответственности за:

    Отказы или задержки со стороны университетов, визовых служб или других третьих лиц.
    Убытки или неудобства, вызванные сбоями в поездке или непредвиденными обстоятельствами.
    Ошибки или упущения в информации, предоставленной пользователями.
    Наша общая ответственность ограничена суммой, уплаченной за конкретную предоставленную услугу.

    7. Интеллектуальная собственность
    Весь контент на UniverMondo.com, включая текст, графику, логотипы и дизайн, является собственностью UniverMondo и защищен законами об авторском праве и интеллектуальной собственности.

    Вы не можете воспроизводить, распространять или использовать наш контент без предварительного письменного согласия.

    8. Политика конфиденциальности
    Пользуясь нашими услугами, вы соглашаетесь на сбор и использование вашей личной информации, как указано в нашей Политике конфиденциальности.

    9. Прекращение предоставления услуг
    Мы оставляем за собой право прекратить или приостановить доступ к нашим услугам, если:

    Вы нарушаете настоящие Условия.
    Вы предоставляете ложную или неполную информацию.
    В таких случаях уже уплаченные сборы не подлежат возврату.

    10. Ссылки третьих лиц
    Наш веб-сайт может содержать ссылки на внешние веб-сайты или службы. UniverMondo не несет ответственности за содержание, политику конфиденциальности или условия этих сторонних сайтов. Используйте эти ссылки на свой страх и риск.

    11. Изменения в Условиях и положениях
    Мы можем периодически обновлять настоящие Условия и положения. Изменения будут публиковаться на этой странице с обновленной датой вступления в силу. Вы несете ответственность за регулярный просмотр этих условий.

    12. Применимое право
    Настоящие Условия и положения регулируются законами [Укажите юрисдикцию, например, Италия]. Любые споры, возникающие в связи с этими условиями, будут разрешаться в судах [Укажите местоположение].

    13. Контактная информация
    Если у вас есть какие-либо вопросы или опасения по поводу настоящих Условий и положений, свяжитесь с нами:
    UniverMondo
    Электронная почта: univermondo@gmail.com
    Веб-сайт: univermondo.com

    `
    ],
    faq1: {
      question: ['Какие документы нужны, чтобы поступить в итальянский вуз?'],
      answer: [
        'Требования и пакет документов подбираются под каждую отдельную программу. Общие документы - это:',
        '1. Диплом с транскриптом',
        '2. Аттестат с транскриптом',
        '3. Рекомендательное письмо',
        '4. Резюме',
        '5. Мотивационное письмо',
        'Если вам нужно подобрать пакет документов и требования на определённую специальность и/или программу, то вы можете записаться на консультацию. По окончании консультации вы получите файл со всеми необходимыми данными.',
      ],
    },
    faq2: {
      question: ['Я из Казахстана/Таджикистана/Армении... Сможете ли вы мне помочь?'],
      answer: ['Да, мы помогаем поступить и получить стипендию студентам со всего мира.'],
    },
    faq3: {
      question: ['Как можно начать с вами сотрудничать?'],
      answer: [
        'Оставьте заявку на интересующий тариф на нашем сайте. В скором времени мы с вами свяжемся, чтобы назначить ознакомительную консультацию, чтобы познакомиться и понять сможем ли мы вам помочь. После консультации мы подпишем договор, где будут прописаны обязательства с нашей стороны и со стороны клиента. После предоплаты мы начинаем работать по процедуре.',
      ],
    },
    faq4: {
      question: ['На каких специальностях можно учиться бесплатно?'],
      answer: [
        'Во всех государственных вузах, независимо от программы, можно учиться бесплатно. Несколько программ, по которым учатся студенты:',
        '- Master Degree in Fashion Studies;',
        '- Master Degree in Anglo-American Studies;',
        '- Master Degree in Tourism Strategy, Cultural Strategy and Made in Italy;',
        '- Master Degree in Cognitive Science and Theory of Communication;',
        '- Bachelor Degree in Sustainable Building Engineering.',
        'И многие другие программы...',
      ],
    },
    faq5: {
      question: ['Можно ли поступить в итальянский вуз после 11 класса?'],
      answer: [
        'К сожалению, чтобы поступить на бакалавр в Италии, итальянская система запрашивает 12 лет академического образования. Один из вариантов - это поступить на foundation год в университет Италии. Чаще он не предусматривает скидки и гранты, но по окончании можно поступать в итальянский вуз и учиться бесплатно со стипендией. Мы также помогаем поступить на программу foundation. С подробностями можно ознакомиться в описании тарифа «foundation» или «foundation + полное сопровождение VIP».',
      ],
    },
    faq6: {
      question: ['Правда, что можно учиться полностью бесплатно и со стипендией?'],
      answer: [
        'Правда. Стоимость обучения в Италии рассчитывается исходя из доходов семьи. Если сумма доходов не превышает лимитов, то студента освобождают от оплаты за обучение. Если студент выигрывает региональную стипендию, то он освобождается от оплаты за университет и получает стипендию в размере до 7 тысяч евро.',
      ],
    },
    faq7: {
      question: ['На каком языке проходит обучение?'],
      answer: [
        'Обучение проходит на английском или на итальянском языке. Уровень английского языка для поступления определяется каждым университетом и даже каждой отдельной программой. Запрашиваемый средний уровень B2.',
      ],
    },

    Stuff: [
      {
          job: 'Основатель',
          name: 'Бахром Бахтиярович',
          image: "/images/Founder.jpg",
          desc: 'Когда я только начинал, процесс поступления казался невероятно сложным: выбор университета, оформление документов, поиск финансирования. Но, пройдя через это, я понял, что моя миссия - помогать другим воплощать мечты об обучении за границей. Поэтому я создал UniverMondo - проект, который помогает студентам поступать в ведущие университеты мира, получать стипендии и открывать новые возможности. Мы работаем с каждым индивидуально, чтобы вы точно знали, что делать на каждом этапе и добивались своих целей!',
      },
      {
          job: 'Генеральный директор',
          name: 'Анастасия Сергеевна',
          image: "/images/SEO.jpg",
          desc: 'Когда я сама проходила этот путь, мне было сложно разобраться во всех нюансах: от выбора университета до подготовки документов. Но этот опыт дал мне понимание, как избежать ошибок и добиться максимальных результатов. Теперь я делюсь своими знаниями через UniverMondo - сервис, который помогает абитуриентам уверенно поступать в лучшие университеты мира. Вместе мы найдем подходящую программу, подготовим документы и повысим ваши шансы на стипендию. Образование за границей - это не просто диплом, это новый этап жизни. Готовы сделать первый шаг? Запишитесь на бесплатную консультацию.',
      },
    ],    

  },
  uz: {

    services: 'Xizmatlar', // Услуги
    aboutUs: 'Biz haqimizda',
    contact: 'Aloqa',
    reviews: 'Sharhlar',
    que: 'Savollar va javoblar',
    que2: 'Tez-tez so`raladigan savollar',
    products: [
      { name: 'Universitetga kirish', description: 'Universitetga kirish (stipendiya bilan yoki unsiz)', href: '/Admission', icon: AcademicCapIcon },
      { name: 'Viza olishda yordam', description: 'Viza olish va hujjatlarni topshirish jarayonida yordam', href: '/Visa', icon: GlobeAltIcon },
    ],
    HeroSection3: [
      'Biz bilan bog‘laning'
    ],
    HeroSection1: [
      'Biz bilan Italiyadagi eng yaxshi universitetlarning eshiklarini oching!'
    ],
    HeroSection2: [
      "Biz talabalarga Italiya universitetlariga kirishda, bepul o'qishda undan tashqari",
      '7000€',
      'Yevrogacha  stipendiya olishingizda yordam beramiz. Orzularingiz sari biz bilan birinchi qadamni tashlang!',
    ],
    Pricing1: [
      'Narxlar'
    ],
    Pricing2: [
      'O‘qish va sayohat qilish ehtiyojlaringizga mos keladigan reja tanlang.'
    ],
    Pricing3: [
      'Biz Italiyadagi universitetga kirish, viza rasmiylashtirish yoki sayohatlarni tashkil qilish bo‘yicha yordam ko‘rsatadigan moslashuvchan rejalarni taklif etamiz.'
    ],
    Pricing4: [
      'Boshlang‘ich'
    ],
    Pricing5: [
      'Universitetga kirish va sayohatlarni rejalashtirishda asosiy yordamga muhtoj talabalar uchun ideal.'
    ],
    Pricing6: [
      'Universitetga ariza topshirishda yordam'
    ],
    Pricing7: [
      'Viza olishda yordam'
    ],
    Pricing8: [
      'Sayohatlarni rejalashtirishda asosiy qo‘llab-quvvatlash'
    ],
    Pricing9: [
      'Buyurtma berish'
    ],
    Pricing10: [
      'Standart'
    ],
    Pricing11: [
      'Ta’lim, vizalar va sayohat logistikasi bo‘yicha keng qamrovli yordamga muhtoj talabalar uchun ideal.'
    ],
    Pricing12: [
      'Universitetga ariza + insho tekshiruvi'
    ],
    Pricing13: [
      'Viza rasmiylashtirish + hujjatlarni tekshirish'
    ],
    Pricing14: [
      'Sayohat logistikasi bo‘yicha batafsil rejalashtirish'
    ],
    Pricing15: [
      'Premium'
    ],
    Pricing16: [
      'Universitetga ariza topshirish, vizalar va sayohatlarni tashkil qilishda to‘liq yordam kerak bo‘lgan talabalar uchun to‘liq reja.'
    ],
    Pricing17: [
      'Universitetga ariza topshirish bo‘yicha to‘liq qo‘llab-quvvatlash + shaxsiy maslahatchi'
    ],
    Pricing18: [
      'Vizalar bo‘yicha tezkor yordam + huquqiy qo‘llab-quvvatlash'
    ],
    Pricing19: [
      'Sayohatni har tomonlama rejalashtirish (parvozlar, yashash joyi va boshqalar)'
    ],
    Stats2: [
      'Bizning yordamimiz bilan o‘qishga kirgan talabalar'
    ],
    Stats1: [
      'Jamoaning ta’lim va turizm sohasidagi yillik tajribasi'
    ],
    Stats3: [
      'Biz yordam bergan Italiya universitetlari'
    ],
    textSpecials: [
      'Xususiyatlar:'
    ],
    textBest: [
      'Eng yaxshi bo‘yicha:'
    ],
    HowItWorks1: [
      'Bu qanday ishlaydi?'
    ],
    HowItWorks2: [
      '1.Saytda ariza qoldirasiz'
    ],
    HowItWorks3: [
      'Oddiy shaklni to‘ldiring va biz jarayonni darhol boshlaymiz!'
    ],
    HowItWorks4: [
      '2.Biz siz bilan bog‘lanamiz va maslahat beramiz'
    ],
    HowItWorks5: [
      'Arizadan so‘ng, bizning mutaxassislarimiz siz bilan batafsil maslahatlashish uchun bog‘lanadi.'
    ],
    HowItWorks6: [
      '3.Universitet va dastur tanlash'
    ],
    HowItWorks7: [
      'Eng yaxshi universitet va mos ta’lim dasturini tanlashda sizga yordam beramiz.'
    ],
    HowItWorks8: [
      '4.Ariza tayyorlash va topshirish'
    ],
    HowItWorks9: [
      'Kerakli barcha hujjatlarni tayyorlaymiz va tanlangan universitetga ariza topshamiz.'
    ],
    HowItWorks10: [
      '5.Siz qabul qilinasiz va hayotingizning yangi bosqichini boshlaysiz!'
    ],
    HowItWorks11: [
      'Qarorni qabul qilganingizdan so‘ng, siz o‘z akademik hayotingizning yangi bosqichiga kirishingiz mumkin!'
    ],
    Services1: [
      'Biz sizning qulayligingiz uchun xizmat qilamiz'
    ],
    Services2: [
      'Murakkab jarayonlarni soddalashtiramiz va sizni asosiy narsaga e’tibor qaratishga yordam beramiz.'
    ],
    Services3: [
      'Maslahat va universitet tanlash'
    ],
    Services4: [
      'Ma’lumotlaringizni o‘rganamiz va eng yaxshi dasturlarni tanlaymiz.'
    ],
    Services5: [
      'Hujjatlarni tayyorlash'
    ],
    Services6: [
      'Tarjimalar, qonuniylashtirish va hujjatlarni universitetlarga yuborishda yordam beramiz.'
    ],
    Services7: [
      'Ariza topshirish va qo‘llab-quvvatlash'
    ],
    Services8: [
      'Arizalarni rasmiylashtiramiz, jarayonni nazorat qilamiz va barcha savollarga maslahat beramiz.'
    ],
    Services9: [
      'Ko‘chishga tayyorgarlik'
    ],
    Services10: [
      'Viza, turar joy va yangi mamlakatda moslashishda yordam beramiz.'
    ],
    Services11: [
      'Mutaxassislarga ishonish uchun 5 sabab'
    ],
    Services12: [
      'Sifat kafolati'
    ],
    Services13: [
      'Italiya universitetlariga kirishning barcha nozik jihatlarini bilamiz.'
    ],
    Services14: [
      'Vaqtni tejash'
    ],
    Services15: [
      'Barcha murakkab tayyorgarlik bosqichlarini o‘z zimmasiga olamiz.'
    ],
    Services16: [
      'Shaxsiy yondashuv'
    ],
    Services17: [
      'Har bir bosqichda individual maslahat va yordam.'
    ],
    Services18: [
      'Italiyadagi hamkorlar'
    ],
    Services19: [
      'Biz universitetlar bilan to‘g‘ridan-to‘g‘ri ishlaymiz.'
    ],
    Services20: [
      '24/7 qo‘llab-quvvatlash'
    ],
    Services21: [
      'Tezkor yordam va savollaringizga javoblar.'
    ],
    About1: [
      'UniverMondo\'ga xush kelibsiz',
    ],
    About2: [
      'Italiyaning hohlagan Oliy O`quv yurtlarida tahsil olishingizda, Evropada yashash orzuingizni amalga oshirishda sizga yaqindan yordam bera olamiz. Universitetga qabul jarayonlari, hujjatlar, viza savollari, sayohat qilish bo`yicha muhim maslahat kerak bo`lsa, albatta bizga murojaat qiling.',
    ],
    About3: [
      'Bizning tajribamiz',
    ],
    About4: [
      '5 yillik muvaffaqiyat, 430 dan ortiq talabalarga yordam berish va 50 dan ortiq universitetlarga kirish.'
    ],
    About5: [
      'Universitetga kirish',
    ],
    About6: [
      'Talabalar ariza topshirishdan stipendiya olishgacha barcha bosqichlarda yordam beramiz, Italiyada silliq akademik yo‘lni ta’minlaymiz.'
    ],
    About7: [
      'Viza qo‘llab-quvvatlash',
    ],
    About8: [
      'Talaba vizalarini rasmiylashtirishda to‘liq qo‘llab-quvvatlash: hujjatlarni topshirishdan intervyuga tayyorgarlikgacha, jarayonni maksimal darajada soddalashtirib.'
    ],
    About9: [
      'Sayohatlarda shaxsiy qo‘llab-quvvatlash',
    ],
    About10: [
      'Chipta sotib olishdan yashash joyiga qadar — talabalar va ularning oilalarini Italiyadagi yangi hayotga tayyorgarlikning har bir bosqichida qo‘llab-quvvatlaymiz.'
    ],
    About11: [
      'Bizning missiyamiz',
    ],
    About12: [
      'Dunyoning eng madaniy jihatdan boy mamlakatlaridan birida talabalarining orzulariga erishishlariga yordam beramiz. Har bir bosqichda shaxsiy qo‘llab-quvvatlash taqdim etib, sizning ta’lim yo‘lingizni oson va qiziqarli qilamiz.'
    ],
    About13: [
      'Biz bilan bog‘laning',
    ],
    About14: [
      'Keyingi qadamni qo‘yishga tayyormisiz? Shaxsiylashtirilgan yordam va qo‘llab-quvvatlash olish uchun biz bilan bog‘laning.',
    ],
    Admission1: [
      'Bizning hamkorligimiz qanday amalga oshiriladi?',
    ],
    Admission2: [
      'Tanishamiz',
    ],
    Admission3: [
      'Avvalo, siz bilan bepul tanishtiruv maslahatida tanishamiz, sizga kerakli tarifni tanlashda yordam berish va ma\'lumot berish uchun.',
    ],
    Admission4: [
      'Shartnoma tuzamiz',
    ],
    Admission5: [
      'Keyin biz hamkorligimizning barcha tafsilotlari yozilgan shartnoma tuzamiz.',
    ],
    Admission6: [
      'Jarayonni boshlaymiz',
    ],
    Admission7: [
      'Oldindan to‘lovdan so‘ng biz bitta yoki bir nechta universitetga kirish jarayonida ishlashni boshlaymiz.',
    ],
    Admission8: [
      'Viza va stipendiya ustida ishlash',
    ],
    Admission9: [
      'Sizning tarifingizga qarab, keyin biz stipendiya olish va viza hujjatlarini tayyorlash ustida ishlaymiz.',
    ],
    Admission10: [
      'ITALIYADAGI UNIVERSITETDA QANDAY QILIB BEPUL O‘QISH MUMKIN',
    ],
    Admission11: [
      'Italiyaning davlat universitetlarida tahsil olish SNG davlatlarining ko‘plab universitetlaridan arzonroq bo‘lishiga qaramay, grant olish va bepul o‘qish imkoniyati ham mavjud. Har yili Italiyadagi mintaqaviy tashkilotlar talabalarni moddiy yordam sifatida stipendiyalar bilan ta’minlaydilar. Bu stipendiya Italiya me\'yorlariga ko‘ra o‘rtacha daromaddan past bo‘lgan talabalar uchun mo‘ljallangan. Uni olish oson, shuning uchun bizning davlatlardan ko‘pchilik o‘z orzusidagi stipendiya tufayli bu yerda o‘qishadi.',
    ],
    Admission12: [
      'Talaba ushbu stipendiyani olganda qanday imtiyozlarga ega bo‘ladi:',
    ],
    Admission13: [
      'Bepul o‘qish.',
    ],
    Admission14: [
      'Yiliga 7000-8000€ stipendiya.',
    ],
    Admission15: [
      'Yotoqxona.',
    ],
    Admission16: [
      'Yiliga 7000-8000€ stipendiya.',
    ],
    Admission17: [
      'Kafexonada kompleks ovqatlanish bepul yoki chegirmali narxda.',
    ],
    Admission18: [
      'Jamoat transportida chegirma yoki bepul yo‘lovchi.',
    ],
    Admission19: [
      'Miqdor va aniq imtiyozlar talaba o‘qiydigan mintaqaga bog‘liq. Masalan, Toskana mintaqasida talabalar kichik miqdordagi naqd pul to‘lovi olishadi, lekin talabxonalar va ovqatlanish bepul.',
    ],
    Body1: [
      'Eng yaxshi universitetlar va dasturlarni kashf eting',
    ],
    Body2: [
      'Orzularingiz uchun yaratilgan eng yaxshi universitetlar va tayyorgarlik dasturlarini o‘rganing. Qabul qilish, dasturlar va boshqa ko‘plab narsalar haqida bilishingiz kerak bo‘lgan hamma narsani bilib oling.',
    ],
    Body3: [
      'Universitetlar ro‘yxati',
    ],
    Contacts1: ['Kontaktlar'],
    Contacts2: ['Biz bilan bog‘laning'],
    Contacts3: ['Telefon (Italiya): +39 351 3554047'],
    Contacts4: ['Telefon (O‘zbekiston): +9989(77)309-18-80'],
    Contacts5: ['Pochta:'],
    Contacts6: ['Moskva: U. Primerna, d. 1, Moskva, Rossiya'],
    Contacts7: ['Toshkent - Tez orada ochiladi!'],
    Contacts8: ['Arizangiz uchun rahmat, biz yaqinda siz bilan bog‘lanamiz!'],
    Contacts9: ['← Forma ga qaytish'],
    Contacts10: ['Bizga yozing'],
    Contacts11: ['Ism'],
    Contacts12: [
      "Aloqa ma'lumotlari",
      "Telegram, WhatsApp yoki Email",
      "(Majburiy emas) Qisqacha o'zingiz haqingizda yozing: ingliz va italyan tilini bilish darajangiz, qaysi ta'lim darajasi va mutaxassislikka hujjat topshirishni rejalashtirayotganingiz, avvalgi ta'limingiz. Stipendiya olish uchun ariza bermoqchimisiz? Qaysi davlatdansiz?"
    ],
    Contacts13: ['Xabar'],
    Contacts14: ['Yuborilmoqda...'],
    Contacts15: ['Yuborish'],
    Circle1: ['Murojaatingiz uchun rahmat, biz yaqinda siz bilan bog‘lanamiz'],
    Circle2: ['Bepul Konsultatsiya'],
    Circle3: ['Ism'],
    Circle4: ['Sizning ismingiz'],
    Circle5: ['Kontakt ma’lumotlari'],
    Circle6: ['Telegram, WhatsApp yoki Email'],
    Circle7: ['Yuborilmoqda...'],
    Circle8: ['Yuborish'],
    Footer1: ['UniverMondo'],
    Footer2: ['Italiya universitetlariga kirishda sizning hamkoringiz'],
    Footer3: ['Kontaktlar'],
    Footer4: ['Pochta:'],
    Footer5: ['Telefon (Italiya): +39 351 3554047'],
    Footer6: ['Telefon (O‘zbekiston): +998 (90) 123-45-67'],
    Footer7: ['Moskva manzili: U. Primerna, d. 1, Moskva, Rossiya'],
    Footer8: [',,Toshkent - Ko‘kcha Darvoza ko‘chasi, 581’’ Tez orada ochiladi!'],
    Footer9: ['Tezkor havolalar'],
    Footer10: ['Biz haqimizda'],
    Footer11: ['Xizmatlar'],
    Footer12: ['Kontaktlar'],
    Footer13: ['Ijtimoiy tarmoqlar'],
    Footer14: ['Barcha yangiliklar va voqealardan xabardor bo‘lish uchun bizning ijtimoiy tarmoqlardagi sahifalarimizga qo‘shiling:'],
    Footer15: ['Barcha huquqlar himoyalangan {year} UniverMondo'],
    Footer16: ['Foydalanuvchi shartnomasi'],
    Footer17: ['Maxfiylik siyosati'],
    Footer18: ['Cookie fayllari siyosati'],
    Footer19: ['Shartlar va qoidalar'],
    RP1: ['Kamroq ko`rsatish'],
    RP2: ['Ko`proq o`qish'],
    RTitle: ['Talabalarimizdan fikr-mulohazalar'],
    ReviewsTitle: ['Talabalarimiz fikrlari'],
    Reviews: [
      {
        name: 'Muxutdinova Zamira',
        review: 'Menga 18 yosh, men Rossiyadanman, va 11 yillik o‘qishdan keyin Italiyaga o‘qishga kirdim. Men UniverMondo kompaniyasining VIP Foundation xizmatlaridan foydalandim, jumladan uy-joy bilan ta’minlash. Hozir Paviyada Foundation dasturida tahsil olmoqdaman. Ushbu kompaniyaga cheksiz minnatdorchilik bildiraman, chunki ular menga taqdim etgan yordam va ma’lumotni o‘zim mustaqil ravishda olish imkoni yo‘q edi. Juda ko‘p tafsilotlar, byurokratik jarayonlar, minglab savollar — har bir bosqichda ushbu kompaniya agentlari qo‘ldan tutib yurib yordam berdi. Ular har doim aloqada edi, savollarimga javob topdilar va eng muhimi, xodimlari Italiyada joylashgan bo‘lib, aniq va ishonchli ma’lumotlar taqdim etishga tayyor.',
        location: 'Qozon, Rossiya',
        image: "/images/reviews/review1.jpg",
        preview: "/images/reviews/preview1.png",
        video: `${process.env.PUBLIC_URL}/images/reviews/review1.MP4`,
        Rinsta: ['zamiraaa_am'],
      },
      {
        name: 'Xojakbar Juraxonov',
        review: 'Men O‘zbekistonning Toshkent shahridanman. Men Italiyada lingvistika fakultetida o‘qiyman. UniverMondo jamoasi ishiga oid fikrimni bildirmoqchiman. Men VIP paket oldim, unda joylashish, uy-joy va universitetga kirish kiritilgan edi. Men ularning ishidan juda mamnunman. Italiyaga kelgach, bizda hech qanday muhim muammolar bo‘lmadi. Jamoaning ishidan juda mamnunman. Barchaga tavsiya qilaman.',
        location: 'Toshkent, O‘zbekiston',
        image: "/images/reviews/review2.jpg",
        preview: "/images/reviews/preview2.png",
        video: `${process.env.PUBLIC_URL}/images/reviews/review2.MP4`,
        Rinsta: ['hojackbar'],
      },
      {
        name: 'Xamidov Sardor',
        review: 'Men oddiy talabaman, bir necha yil ichida katta yutuqlarga erishdim. Bir yil oldin UniverMondo agentligiga murojaat qildim va ular menga katta qadamlar tashlashga yordam berdi, hamma narsani mukammal tarzda tashkil qilishdi. Menda hech qanday qiyinchiliklar bo‘lmadi. UniverMondo agentligi raqamsiz birinchi o‘rinda ekanligiga ishonch bilan ayta olaman.',
        location: 'Sirdaryo, O‘zbekiston',
        image: "/images/reviews/review3.png",
        preview: "/images/reviews/preview3.png",
        video: `${process.env.PUBLIC_URL}/images/reviews/review3.mp4`,
        Rinsta: ['sardorkh1_'],
      },
      {
        name: 'Togʻbegiev Bahrulloh',
        review: 'Men Milanda til kurslarida tahsil olaman. Bir yil oldin men hujjatlarni topshirish va Italiyadagi til maktabida o`qitishni tashkil qilish uchun agentlik qidirayotgan edim. Bunda menga UniverMondo jamoasi yordam berdi. Ularga rahmat, jarayon juda oddiy va tushunarli bo`lib chiqdi. Italiyadagi til maktablarida o`qishni rejalashtirayotgan har bir kishiga ushbu agentlikni ishonch bilan tavsiya qilishim mumkin.',
        location: 'Samarqand, O‘zbekiston',
        image: "/images/reviews/preview4.png",
        preview: "/images/reviews/preview4.png",
         video: `${process.env.PUBLIC_URL}/images/reviews/review4.mp4`,
         Rinsta: ['sadullaevich.01'],
      },
      {
        name: 'Alok Singaniya',
        review: 'Assalomu alaykum, mening ismim Alok Kumar, men Hindistondanman. Avvalo, Italiyada o‘qish imkoniyatini bergan UniverMondo agentligiga chin dildan minnatdorchilik bildiraman. Yaqinda men Injeneriya fakultetining "Sanoat avtomatizatsiyasi injeneriyasi" yo‘nalishiga qabul qilindim va ularning VIP xizmatlaridan foydalanishga qaror qildim, bu xizmatlar turar joy topishda va stipendiya olishda yordamni o‘z ichiga oladi. Agentlik menga juda yaxshi munosabatda bo‘ldi va barcha hujjatlarimni va qabul jarayonini a’lo darajada tashkil qildi. Ular menga turar joy topishda, stipendiya uchun hujjatlar tayyorlashda va barcha ishlarning mukammal bajarilishida yordam berishdi. Ularning professional yondashuvi va yordami jarayonni men uchun stresssiz qilib qo‘ydi. Ayniqsa, Imranondoga minnatdorchilik bildirmoqchiman, u shaxsan qabul xatini olishimga ishonch hosil qildi va barcha bosqichlarga g‘amxo‘rlik qildi. Uning sa’y-harakatlari ajoyib edi, va men bu ishlarni uning yordamisiz uddalay olmas edim. Yana bir bor UniverMondo va Imranondoga Italiyada o‘qish orzumni ro‘yobga chiqarganliklari uchun chin dildan minnatdorchilik bildiraman. Bu ajoyib imkoniyat uchun katta rahmat!',
        location: 'Dehli, Hindiston',
        image: "/images/reviews/preview5.png",
        preview: "/images/reviews/preview5.png",
        video: `${process.env.PUBLIC_URL}/images/reviews/review5.MP4`,
        Rinsta: ['Singhania_alok001'],
      }      
    ],
    
    universities: [
      {
        name: "Bocconi Universiteti",
        location: "Milan",
        bestFor: ["Iqtisodiyot", "Biznes boshqaruvi", "Moliyaviy"],
        features: [
          "Iqtisodiyot, biznes va moliya sohasida karyera qurishni maqsad qilgan talabalar uchun Italiyadagi yetakchi universitetlardan biri.",
          "Menejment, iqtisodiyot va moliya sohasida xalqaro miqyosda tan olingan obro‘.",
          "Ingliz tilida o‘qitiladigan dasturlar butun dunyodan talabalarni jalb qiladi.",
        ],
        image: "bocconi.jpg",
      },
      {
        name: "Sapienza Universiteti (Rim)",
        location: "Rim",
        bestFor: ["Tibbiyot", "Muhandislik", "Arxeologiya"],
        features: [
          "Italiyaning eng qadimgi va yirik universiteti bo‘lib, keng ko‘lamli ta'lim dasturlarini taklif etadi.",
          "Tibbiyot, muhandislik, arxeologiya va gumanitar fanlar bo‘yicha e’tirof etilgan.",
          "Tibbiy muassasalar bilan mustahkam aloqalar, bu talabalarni amaliy rivojlanish imkoniyatlari bilan ta’minlaydi.",
        ],
        image: "sapienza.jpg",
      },
      {
        name: "Pisa Universiteti",
        location: "Piza",
        bestFor: ["Astronomiya", "Muhandislik", "Matematika"],
        features: [
          "Universitet ilm-fan, xususan astronomiya, matematika va muhandislik sohalaridagi yutuqlari bilan mashhur.",
          "Universitet olimlari zamonaviy fizika va matematika rivojiga katta hissa qo‘shgan.",
          "Ilmiy tadqiqot va yuqori texnologiyalarga yo‘naltirilgan dasturlar.",
        ],
        image: "pisa.png",
      },
      {
        name: "Turin Universiteti",
        location: "Turin",
        bestFor: ["Iqtisodiyot", "Huquqshunoslik", "Biologiya"],
        features: [
          "Turin Universiteti huquq, iqtisodiyot va biologiya bo‘yicha kuchli dasturlari bilan mashhur.",
          "Mahalliy sanoat va tadqiqot institutlari bilan faol hamkorlik.",
          "Talabalar yetakchi huquq va iqtisodiyot tashkilotlarida amaliyot o‘tish imkoniyatiga ega.",
        ],
        image: "turin.png",
      },
      {
        name: "Genuya Universiteti",
        location: "Genuya",
        bestFor: ["Muhandislik", "Dengizshunoslik", "Arxitektura"],
        features: [
          "Dizayn va texnologiyaga yo‘naltirilgan muhandislik va arxitektura fakultetlari bilan mashhur.",
          "Universitet dengizshunoslik va ekologik texnologiyalar sohasidagi tadqiqotlari bilan ham tanilgan.",
          "Talabalar dengiz sanoati bilan mustahkam aloqalar orqali amaliy ko‘nikmalarni egallaydilar.",
        ],
        image: "genova.png",
      },
      {
        name: "Ca’ Foscari Universiteti (Venetsiya)",
        location: "Venetsiya",
        bestFor: ["Iqtisodiyot", "Gumanitar fanlar", "Tilshunoslik"],
        features: [
          "Universitet gumanitar va ijtimoiy fanlari, jumladan iqtisodiyot va tilshunoslik bo‘yicha mashhur.",
          "Ingliz tilidagi dasturlar butun dunyodan talabalarni jalb qiladi.",
          "Madaniy va ta'lim muassasalari bilan mustahkam aloqalar talabalar uchun noyob professional imkoniyatlarni taqdim etadi.",
        ],
        image: "venice.png",
      },
      {
        name: "Siena Universiteti",
        location: "Siena",
        bestFor: ["Tibbiyot", "San'at tarixi", "Iqtisodiyot"],
        features: [
          "Siena Universiteti tibbiyot va tibbiyot fanlari bo‘yicha kuchli dasturlarni taklif etadi.",
          "Siena — boy tarixga ega madaniy markaz bo‘lib, san'at tarixi bo‘yicha tadqiqot dasturlarini rivojlantiradi.",
          "Gumanitar va ijtimoiy fanlar bo‘yicha e’tirof etilgan.",
        ],
        image: "siena.png",
      },
      {
        name: "Padua Universiteti",
        location: "Paduya",
        bestFor: ["Tibbiyot", "Muhandislik", "Huquq"],
        features: [
          "Dunyoning eng qadimgi universitetlaridan biri bo‘lgan Padua tibbiyot va huquq sohasidagi yutuqlari bilan tanilgan.",
          "Robototexnika va biotibbiy muhandislikni o‘z ichiga olgan muhandislik sohasidagi kuchli dasturlar.",
          "Ilmiy tadqiqotlarga faol ishtirok etadi va a’lo darajadagi laboratoriya sharoitlariga ega.",
        ],
        image: "padua.png",
      },
      {
        name: "Florensiya Universiteti",
        location: "Florensiya",
        bestFor: ["San'at", "Arxitektura", "Tarix"],
        features: [
          "Florensiya Universiteti san'at, san'at tarixi va arxitektura bo‘yicha dunyodagi yetakchi universitetlardan biri sifatida tanilgan.",
          "Madaniy meros va arxitektura an'analarini faol o‘rganadi.",
          "Xalqaro talabalar uchun ingliz tilida ko‘plab dasturlar mavjud.",
        ],
        image: "florence.png",
      },
      {
        name: "Katania Universiteti",
        location: "Katania",
        bestFor: ["Fizika", "Muhandislik", "Iqtisodiyot"],
        features: [
          "Katania Universiteti fizika, xususan astrofizika va nazariy fizika sohasidagi ilmiy tadqiqotlari bilan mashhur.",
          "Muhandislik fakultetlari yangi texnologiyalarni va energetika hamda mashinasozlik sohasidagi tadqiqotlarni rivojlantiradi.",
          "Iqtisodiyot dasturlari kuchli xalqaro yo‘nalishga ega.",
        ],
        image: "catania.jpg",
      },
      {
        name: "Toskana Universiteti",
        location: "Toskana",
        bestFor: ["Qishloq xo‘jaligi fanlari", "Ekologiya", "O‘rmonchilik"],
        features: [
          "Toskana Universiteti barqaror rivojlanish, ekologiya va qishloq xo‘jaligi fanlari bo‘yicha tadqiqotlarni faol rivojlantiradi.",
          "O‘rmonchilik va tabiiy resurslar bo‘yicha dasturlar mintaqaning haqiqiy ekologik muammolariga yo‘naltirilgan.",
          "Talabalar amaliy loyihalarda ishtirok etib, muhim ish ko‘nikmalarini egallaydilar.",
        ],
        image: "tuscany.png",
      },
      {
        name: "Peruja Universiteti",
        location: "Peruja",
        bestFor: ["Sotsiologiya", "Siyosatshunoslik", "Huquq"],
        features: [
          "Peruja Universiteti sotsiologiya, siyosatshunoslik va huquq bo‘yicha kuchli dasturlari bilan mashhur.",
          "Ta'lim va ijtimoiy fanlar bo‘yicha tadqiqot loyihalarida fanlararo yondashuv bilan e’tirof etilgan.",
          "Xalqaro tashkilotlarda amaliyot va tajribalar uchun a’lo imkoniyatlar mavjud.",
        ],
        image: "perugia.png",
      },
      {
        name: "Pavia Universiteti",
        location: "Pavia",
        bestFor: ["Tibbiyot", "Muhandislik", "Huquq", "Iqtisodiyot"],
        features: [
          "Ta'lim dasturlari va yuqori darajadagi amaliyotlari bilan mashhur. Talabalar yetakchi klinikalarda va tibbiyot muassasalarida ta'lim olishadi.",
          "Muhandislik dasturlari zamonaviy sohalarni, jumladan elektr muhandisligi, mexanika va biotibbiy muhandislikni qamrab oladi, yuqori texnologiyali kompaniyalar bilan hamkorlik qiladi.",
          "Barcha zarur sharoitlarga ega bo‘lgan kichik, qulay shahar bo‘lib, Milan shahridan 30 daqiqada joylashgan.",
        ],
        image: "pavia.png",
      },
    ],    
    foundationPrograms: [
      {
        name: "Foundation Year Rimda (Roma)",
        location: "Rim",
        bestFor: ["Til o‘rganish", "Bakalavriatga tayyorgarlik"],
        features: [
          "Università degli Studi di Roma La Sapienza — Italiyadagi eng yirik universitetlardan biri bo‘lib, bakalavriat yoki magistraturada o‘qishni davom ettirmoqchi bo‘lgan xalqaro talabalar uchun tayyorlov dasturlarini taklif etadi.",
          "Foundation Year dasturlari italyan tilini o‘rganuvchilar uchun ham, xalqaro dasturlar doirasida ingliz tilida o‘qishni rejalashtirgan talabalar uchun mo‘ljallangan."
        ],
        image: "rome.png",
      },
      {
        name: "Foundation Year Florensiyada (Florence)",
        location: "Florensiya",
        bestFor: ["San'at", "Dizayn", "Gumanitar fanlar"],
        features: [
          "Florence University of the Arts — san'at, dizayn, biznes va gumanitar fanlar sohasida o‘qishni rejalashtirgan talabalar uchun intensiv kurslar.",
          "Ingliz va italyan tillarida ta'lim. Florensiya — madaniy va tarixiy markaz bo‘lib, san'at va gumanitar fanlar sohasida talabalar uchun noyob imkoniyatlarni taqdim etadi."
        ],
        image: "florence2.png",
      },
      {
        name: "Foundation Year Paviyada (Pavia)",
        location: "Pavia",
        bestFor: ["Tibbiyot", "Muhandislik", "Iqtisodiyot"],
        features: [
          "Università degli Studi di Pavia — Italiyadagi eng qadimgi universitetlardan biri bo‘lib, xalqaro talabalar uchun bakalavriatga tayyorlov kurslarini taklif etadi.",
          "Akademik ko‘nikmalarni va tilni rivojlantirishga, shuningdek, tibbiyot, muhandislik, iqtisodiyot va boshqa fanlar bo‘yicha o‘qishga tayyorlashga qaratilgan kurslar."
        ],
        image: "pavia.png",
      },
      {
        name: "Foundation Year Bolonyada (Bologna)",
        location: "Bolonya",
        bestFor: ["Til tayyorgarligi", "Akademik tayyorgarlik"],
        features: [
          "Università di Bologna — dunyodagi eng qadimgi universitetlardan biri bo‘lib, xalqaro talabalar uchun tayyorlov dasturlarini taklif etadi.",
          "Dasturlar til tayyorgarligi va o‘quv yo‘nalishlariga bog‘liq akademik kurslarga yo‘naltirilgan."
        ],
        image: "bologna.png",
      },
      {
        name: "Foundation Year Venetsiyada (Venezia)",
        location: "Venetsiya",
        bestFor: ["Iqtisodiyot", "Biznes", "Madaniy tadqiqotlar"],
        features: [
          "Università Ca’ Foscari Venezia — iqtisodiyot, biznes, san'at va madaniy tadqiqotlar bo‘yicha o‘qishga tayyorlanishni xohlovchi talabalar uchun Foundation Year dasturlari.",
          "Venetsiya san'at, madaniyat va tarixni o‘rganishni xohlovchi talabalar uchun noyob muhitni taqdim etadi."
        ],
        image: "venice2.png",
      },
      {
        name: "Foundation Year Turinda (Torino)",
        location: "Turin",
        bestFor: ["Gumanitar fanlar", "Biznes", "Iqtisodiyot"],
        features: [
          "Università degli Studi di Torino — xalqaro talabalar uchun, ayniqsa gumanitar fanlar, biznes va iqtisodiyot bo‘yicha bakalavriat dasturlarida o‘qishni rejalashtirganlar uchun Foundation Year dasturlarini taklif etadi.",
          "Turin — sanoat va universitet markazi bo‘lib, talabalar uchun akademik tayyorgarlik va til ko‘nikmalarini rivojlantirishga qaratilgan dasturlar bilan mashhur."
        ],
        image: "turin.png",
      },
      {
        name: "Foundation Year Sienada (Siena)",
        location: "Siena",
        bestFor: ["Gumanitar fanlar", "Ijtimoiy fanlar"],
        features: [
          "Università degli Studi di Siena — gumanitar va ijtimoiy fanlar bo‘yicha bakalavriat dasturlariga tayyorlashga mo‘ljallangan Foundation Year kurslarini taklif etadi.",
          "Siena — kuchli gumanitar an'analarga ega universitet bo‘lib, bu yerda Foundation Year dasturlari italyan madaniyati va tilini o‘rganishga qaratilgan."
        ],
        image: "siena2.png",
      },
      {
        name: "Foundation Year Pizada (Pisa)",
        location: "Piza",
        bestFor: ["Gumanitar fanlar", "Muhandislik"],
        features: [
          "Università di Pisa — gumanitar va ijtimoiy fanlar, shuningdek, muhandislik bo‘yicha xalqaro talabalar uchun Foundation Year dasturlarini taklif etadi.",
          "Akademik va til ko‘nikmalarini rivojlantirishga qaratilgan tayyorlov kurslari."
        ],
        image: "pisa2.png",
      },
      {
        name: "Foundation Year Komoda (Como)",
        location: "Komo",
        bestFor: ["Iqtisodiyot", "Huquq", "Ijtimoiy fanlar"],
        features: [
          "Università degli Studi dell’Insubria — iqtisodiyot, huquq va ijtimoiy fanlar bo‘yicha Foundation Year dasturlarini taklif etadigan universitet.",
          "Bu kichikroq universitet bo‘lib, xalqaro talabalar uchun yuqori sifatli dasturlarni taqdim etadi."
        ],
        image: "como.png",
      },
    ],
    languagecourses: [
      {
        name: "Scuola Leonardo da Vinci",
        location: "Milan, Rim, Florensiya, Turin",
        bestFor: [
          "Italyan tilini intensiv kurslari",
          "Uzoq muddatli italyan tili kurslari",
          "Italyan tili sertifikatlariga tayyorgarlik"
        ],
        features: [
          "Kichik guruhlar",
          "Tajribalik o'qituvchi-ona til vakillari",
          "Madaniy tadbirlar va ekskursiyalar",
          "Turar joy bilan yordam",
          "Bepul Wi-Fi va o'quv materiallari"
        ],
        image: "milan.png"
      },
      {
        name: "Dilit International House",
        location: "Rim, Italiya",
        bestFor: [
          "Guruhli kurslar",
          "Individual kurslar",
          "Kombinatsiyalangan kurslar",
          "Akademik dasturlar",
          "Onlayn kurslar"
        ],
        features: [
          "Kommunikativ ta'lim yondashuvi",
          "Tajribalik o'qituvchilar",
          "Madaniy tadbirlar",
          "Turar joy bilan yordam",
          "Zamonaviy qulayliklar va bepul Wi-Fi"
        ],
        image: "rome.png"
      }
    ],
    Unis0: ['Til kurslari'], 
    Unis1: ["Universitetlar"],
    Unis2: ["Foundation Dasturlari"],
    Unis3: ["Eng yaxshi yo‘nalishlar:"],
    Unis4: ["Xususiyatlar:"],
    Unis5: ["Biz bilan bog‘laning"],
    Unis6: ["Italiyada o‘qish yo‘lingizni boshlashga tayyormisiz? Maslahat olish uchun biz bilan bog‘laning."],
    Unis7: ["Bizga yozing"],
    
    pricingPlans: [
      {
        title: 'Universitetga qabul bo‘yicha yordam',
        price: '1600€',
        features: [
          'Universitetga qabul qilishda shaxsiy kurator yordami',
          'Universitetlar, dasturlar va talablarni tanlash',
          'Universitet uchun hujjatlarni yig‘ishda yordam',
          'Hujjatlarni notarial tasdiqlash, tarjima qilish va legallashtirishda yordam',
          'Universitetga ariza berish va barcha hujjatlarni bizning tomonimizdan qayta ishlash',
          'Qabul qilish uchun barcha jarayonlarda yordam',
          'Ariza beruvchilar bilan guruh chat',
        ],
        buttonLabel: 'Sotib olish',
      },
      {
        title: 'Stipendiya bo‘yicha yordam',
        price: '2600€',
        features: [
          'Stipendiya olishda shaxsiy kurator yordami',
          'Stipendiya uchun hujjatlarni yig‘ishda yordam',
          'Bank hujjatlari va oilaviy holat ma’lumotlarini taqdim etishda yordam',
          'Motivatsion va tavsiyanoma xatlarida yordam',
          'Hujjatlarni notarial tasdiqlash, tarjima qilish va legallashtirishda yordam',
          'Hamfikrlar bilan guruh chat',
        ],
        buttonLabel: 'Sotib olish',
      },
      {
        title: 'Vizani olish uchun to‘liq yordam',
        price: '1500€',
        features: [
          'Visa anketasini to‘ldirish',
          'Viza intervyusiga yozilish (agar kerak bo‘lsa)',
          'Visa intervyusiga tayyorgarlik (sinov intervyulari)',
          'Barcha hujjatlarni (arizalar, anketalar, tarjimalar, notarial tasdiqlar) tekshirish va tuzatish',
        ],
        buttonLabel: 'Sotib olish',
      },
      {
        title: 'Foundation',
        price: '1500€',
        features: [
          'Universitetlar, dasturlar va talablarni tanlash',
          'Hujjatlarni yig‘ishda yordam',
          'Universitetlarga arizalar berish',
          'Qabul qilish uchun barcha jarayonlarda yordam',
          'Ariza beruvchilar bilan guruh chat',
          'Visa bo‘yicha maslahat berish',
          'Italiyadagi barcha byurokratik masalalar bo‘yicha maslahat',
        ],
        buttonLabel: 'Sotib olish',
      },
      {
        title: 'VIP qo‘llab-quvvatlash',
        price: '4000€',
        features: [
          'Universitetga qabul qilish bo‘yicha to‘liq qo‘llab-quvvatlash',
          'Stipendiya olish bo‘yicha to‘liq qo‘llab-quvvatlash',
          'Uy-joy qidirishda yordam',
          'Aeroportda kurator bilan shaxsiy uchrashuv',
          'Turar joy uchun ruxsatnoma, sug‘urta, soliq kodi va har qanday hujjatlarni to‘ldirishda yordam',
          'Ariza beruvchilar bilan guruh chat',
        ],
        buttonLabel: 'Sotib olish',
      },
      {
        title: 'Boshqa xizmatlar',
        price: '',
        features: [
          'Qabul qilish bo`yicha maslahat. Jamoamizning tajribali kuratori bilan istalgan messenjerda 1 soatgacha (100€) audio/video qo`ng`iroq qilish',
          'Aeroportdan transfer (50€-200€)',
          'Turar joy uchun ruxsatnoma berish, bank hisobini ochishda kurator yordami (115€)',
          'Ariza beruvchilar bilan guruh chat (100€)',
          'Motivatsiya yoki tavsiyanoma xatlarini professional tarzda tayyorlash (190€)',
          'Uy-joy bo‘yicha to‘liq yordam (990€)',
          'Voyaga yetmagan holda Italiyaga ko‘chib o‘tishda "VASIYLIK" hujjati bilan yordam (550€)',
        ],
        buttonLabel: 'Sotib olish',
      },
    ],
    Pricing1: ['Bizning xizmatlarimiz'], 
    Pricing2: ['Mos reja tanlang'],

  
      Visa1: ["Talabalik va turistik vizalarni olishda yordam berish"],
      Visa2: ["Talaba vizasini olish jarayonining har bir bosqichida to‘liq qo‘llab-quvvatlash – maslahatdan boshlab hujjat topshirishgacha, jarayonni osonlashtiramiz."],
      Visa3: ["Viza olish jarayonini qanday osonlashtiramiz"],
      Visa4: ["Biz to‘liq yo‘l-yo‘riq ko‘rsatamiz, barcha hujjatlarning to‘g‘ri va o‘z vaqtida topshirilishini ta’minlaymiz."],
      Visa5: ["Dastlabki maslahat"],
      Visa6: ["Shaxsiy maslahat bilan boshlang, ehtiyojlaringizni baholang va viza talablarini bajarishda yo‘riqnoma oling."],
      Visa7: ["Hujjatlarni tayyorlash"],
      Visa8: ["Bizning jamoamiz barcha zarur hujjatlarni yig‘ish, tayyorlash va tekshirishda sizga yordam beradi."],
      Visa9: ["Hujjatlarni topshirish va kuzatish"],
      Visa10: ["Biz hujjatlarni topshirish jarayonini boshqaramiz va barcha bosqichlarda sizga yordam beramiz."],
      Visa11: ["Safaringizni boshlashga tayyormisiz?"],
      Visa12: ["Bugun biz bilan bog‘laning, chet elda ta’lim olish orzularingizni amalga oshirish uchun bizning ekspert yordamizdan foydalaning."],
      Visa13: ["Biz bilan bog‘lanish"],
      Back: ["Orqaga"],
      Cookies1: ['Cookie haqida xabarnoma'],
      Cookies2: ['Biz sizga eng yaxshi tajribani taqdim etish uchun cookie fayllardan foydalanamiz.'],
      Cookies3: ['Cookie siyosatini o‘qish'],
      Cookies4: ['Biz haqimizda'],
      Cookies5: ['Qabul qilish'],
      Info1: ['User Agreement'],
      Info2: ['Privacy Policy'],
      Info3: ['Cookies Policy'],
      Info4: ['Terms and Conditions'],
      Info5: [
        `Polzovatelskoe muloqotenie
        Ma'lumotlar sanasi: 06.12.2024
        
        UniverMondo.com saytida dobro pojalovat! Poluchaya dostup k nahim uslugam ili ispolzuya ix, vy soglashaetes soblyudat sleduyuschee Polzovatelskoe soglashenie. Pojaluysta, vnimatelno prochtite etot dokument pered ispolzovaniem nashego veb-sayt yoki xizmat.
        
        1. Predostavlyaemye uslugi
        UniverMondo quyidagi xizmatlarni taqdim etadi:
        
        Pomoshch s postupleniem v universiteti Italiya.
        Rukovodstvo po podache zayavleniy na vizu va podderjka v obrabotke.
        Organizatsiya poezdok va logistika.
        Personalizatsiyarovannye gidy, kotorye pomogut studentam sorientirovasya v novoy srede in Italy.
        Vse uslugi podchinyayutsya usloviyam, izlojennym v nastoyashchem soglashenii.
        
        2. Information o polzovatele
        Chtoby vospolzovatsya nashimi uslugami, vy doljny zapolnit nashu kontaktnuyu formu, v kotoroy trebuetsya:
        
        Vashe imya.
        Kratkoe opisanie vashey situatsii (masalan, obrazovatelnye tseli, plany poezdok).
        Vashi kontaktnye dannye (masalan, nomer telefona yoki adres elektronnoy pochta).
        Otpravlyaya etu informatsiyu, vy podtverjdaete, chto:
        
        Predostavlennaya ma'lumot yavlyaetsya tochnoy i pravdivoy.
        Vam ispolnilos 18 let ili u vas est siglasie roditelya/opekuna na ispolzovanie nashix uslug.
        3. Ispolzovanie personalnyh dannyh
        My uvajaem vashu konfidentsialnost. Barcha shaxsiy dannye, otpravlennye cherez nash veb-sayt, bu savolga javob berish uchun Politikoy konfidentsialnosti. Polizyas nashimi uslugami, vy soglashaetes so sleduyushchim:
        
        Vasha ma'lumot budet ispolzovatsya tolko dlya svyazi s vami va predostavleniya zaproshennyx xizmat ko'rsatish.
        My ne budem peredavat vashu informatsiyu tretim litsam, za isklyucheniem sluchaev, kogda bu neobxodimo uchun predostavleniya nashix uslug (masalan, universitet yoki organam po obrabotke viz).
        4. Ogranicheniya obslujivaniya
        Xotya my stremimsya okazyvat tochnuyu va svoevremennuyu pomoshch, vy priznaete, chto:
        
        UniverMondo vystupaet v kachestve posrednika va negarantiruet zachislenie v universitety, odobrenie viz yoki konkretnye natijalar.
        Lyubye zaderjki ili muammolar, voznikaushchie iz-za storonnix organizatsiy (masalan, universitetov, vizovyx otdelov, turistik agentstv), naxodyatsya vne nashego kontrolya.
        Turisticheskie i vizovye uslugi mumkin podchinyatsya vneshnim pravilam, trebovaniyam yoki neprevidennym izmeneniyam.
        5. Obyazannosti polzovatelya
        Ispolzuya nashi uslugi, vy soglashaetes:
        
        Svoevremenno predostavlyat vse neobxodimye dokumenty va informatsiya.
        Sleduyte nasim rekomendatsiyam i sovetam po podache zayavleniy v universitet, vizovym protsesam va organizatsii poezdok.
        Nesti otvetstvennost za dopolnitelnye rashody (misol, registratsiya sbory, transportnye rashody, vizovye sbory).
        6. Plata va oplata
        Plata za nashi uslugi budet soobshena v protsesse konsultatsiya. Usloviya oplaty vklyuchayut:
        
        Vse plateji doljny byt proizvedeny v polnom ob'eme do predostavleniya xizmatlari, agar inoe bo'lmasa.
        Vozvrat sredstv nevozmojen posle nachala predostavleniya uslug, univerMondo vine uchun isklyucheniem sluchaev sboya obslujivaniya.
        7. Otkaz ot otvetstvennosti
        UniverMondo ne neset otvetstvennosti za:
        
        Oshibki ili zaderjki, vyzvannye nesposobnostyu polzovatelya predostavit tochnuyu yoki svoevremennuyu informatsiyu.
        Otkazy ili zaderjki so storony universitetov, vizovyx slujb yoki drugix tretix lits.
        Ubytki yoki neudobstva, vyzvannye sboyami v poezdke yoki nepredvidennymi obstoyatelstvami.
        Nasha otvetstvennost ogranichivaetsya stoimostyu xizmat, predostavlyaemyh UniverMondo.
        
        8. Prekrashchenie predostavleniya uslug
        UniverMondo ostavlyaet za soboy pravo prekratit predostavlenie uslug, agar:
        
        Polzovatel predostavlyaet lojnuyu yoki vvodyashuyu v zablujdenie informatsiyu.
        Polzovatel narushaet usloviya nastoyaschego soglasheniya.
        V takix sluchayax uje uplachennye sbory ne podlejat vozvratu.
        
        9. Izmeneniya v Soglashenii
        My ostavlyaem za soboy pravo obnovlyat yoki izmenyat nastoyaschee Polzovatelskoe oglashenie v lyuboe vremya. Izmeneniya bo'ladi nashrikovani na nashem veb-sayt va stupyat v silu nemedlenno. Polzovatel neset otvetstvennost za periodicheskiy prosmotr bloglasheniya.
        
        10. Primenimoe pravo
        Nastoyashchee soglashenie reguliruetsya zakonami O'zbekiston. Lyubye spory, voznikaushchie v svyazi s nastoyashchim soglashenem, podlejat isklyuchitelnoy yurisdiktsii sudov v O'zbekiston.
        
        11. Kontakt ma'lumotlari
        Agar siz qanday savol bersangiz yoki opaseniya povodu bu savolga javob bersangiz, svyajites s nami po adresu:
        UniverMondo
        Elektronnaya pochta: univermondo@gmail.com
        Veb-sayt: univermondo.com
        
        Xotite, chtoby ya chto-to dobavil yoki izmenil, masalan, osobye pravila vozvrata yoki svedeniya o zashchite dannyx?`
      ],
      Info6: [
        `
        Politika konfidentsialnosti
        Ma'lumotlar sanasi: 02.12.2024
        
        UniverMondo.com saytida my tsenim vashu konfidentsialnost va stremimsya zashchishchat vashi personalnye dannye. Politike konfidentsialnosti e'lon qilinadi, qanday ma'lumot olish, foydalanish va ma'lumot olish, veb-saytlar va xizmatlardan foydalanish.
        
        1. Axborot, kotoruyu my sobraem
        Kogda vy polzuetes nashimi uslugami, my mojem sobirat sleduyushuyu shaxsiy ma'lumot:
        
        Aloqa ma'lumotlari: vashe imya, nomer telefona va adres elektronnoy pochta.
        Opisanie situatsii: svedeniya o vashix obrazovatelnyh tselyax, planax poezdok yoki drugaya sootvetstvuyushchaya ma'lumot, kotoruyu vy predostavlyaete v nashey kontaktnoy formasi.
        Dannye ob ispolzovanii: Information o tom, kak vy vzaimodeystvuete s nahim veb-sayt, vklyuchaya vash IP-adres, tip brauzer va poseschennye stranitsy.
        2. Kak my ispolzuem vashu informasiyu
        Mening foydalanishim mumkin bo'lgan vami axborot:
        
        Dlya obshcheniya s vami o nashix uslugax i otveta na vashi zaprosy.
        Dlya okazaniya pomoshchi s podachey zayavleniy v universitet, vizovymi protsesami, organizatsiey poezdok va soputstvuyushchimi uslugami.
        Dlya uluchsheniya nashego veb-saytlar, mijozlarga xizmat ko'rsatish va obslujivaniya.
        Dlya soblyudeniya pravovyh va normativnyh trebovaniy.
        3. Kak my delimsya vashey informatsiey
        My ne prodaem, ne sdaem v arendu va na obmenivaem vashu lichnuyu informatsiyu. Odnako my mojem delitsya vashimi dannymi s doverennymi tretimi litsami v sleduyushchix slauchayax:
        
        Postavshchiki uslug: dlya uproscheniya podachi zayavleniy v universitet, vizovyx protsessov yoki organizatsii poezdok.
        Yuridicheskie obyazatelstva: agar bu zaruriy zakon, postanovlenie yoki sudebnyy protsess.
        Garantiruem, chto vse treti litsa, obrabatvayushchie vashi dannye, soblyudayut strogie standarty konfidentsialnosti va bezopasnosti.
        
        4. Xranenie i bezopasnost dannyx
        My nadejno xranim vashu lichnuyu informatsiyu, ispolzuya otraslevye standartnye mery shifrovaniya va bezopasnosti.
        
        Srok xraneniya: my xranim vashi dannye tolko stolko vremeni, skolko neobxodimo uchun dostijeniya tseley, izlojennyx v nastoyaschey politike, ili soblyudeniya yuridicheskix obyazatelstv.
        Mery bezopasnosti: my primenyaem nadejnye texnicheskie i organizatsionnye mery uchun predotvrashcheniya nesanktsionirovannogo dostupa, raskritiya, izmeneniya yoki unichtojeniya vashix dannyx.
        5. Vashi prava
        U vas est sleduyushchie prava v otnoshenii vashix shaxsiy dannyx:
        
        Do'st: zaprosit dostup k informatsii o vas, kotoruyu my xranim.
        Ispravlenie: zaprosit ispravlenie netochnyx yoki nepolnyx dannyx.
        Udalenie: zaprosit udalenie vashix personalnyx dannyx v sootvetstvii s trebovaniyami qonunodatelstva.
        Vozrajenie: vozrajayte protiv obrabotki vashix dannyx v opredelennyh tselyax.
        Chtoby vospolzovatsya svoimi pravami, svyajites s nami po adresu univermondo@gmail.com.
        
        6. Fayly cookie va texnologii otlejivaniya
        My ispolzuem fayly cookie va analogik texnologii uluchsheniya vashego opyta prosmotra va sbora dannyx ob ispolzovanii uchun.
        
        Chto takoe fayly cookie? Cookie fayli — bu faqat matnli fayl, siz qurilmangiz, kotorye pomogayut nam ponimat povedenie polzovateley va uluchshat nash veb-sayt.
        
        Cookie-fayllarni boshqarish: cookie-fayllarni boshqarish yoki o'zgartirish mumkin bo'lgan brauzer, lekin bu veb-saytni o'zgartirishi mumkin.
        
        7. Ssylki na storonnie veb-saytlari
        Nash veb-sayt mumkin soderjat sylki na storonnie web-sayty yoki slujby. My ne nesem otvetstvennosti za politiku konfidentsialnosti yoki soderjanie etix vneshnix saytov. My rekomenduem oznakomitsya s ix politik konfidentsialnosti pered tem, kak delitsya svoimi dannymi.
        
        8. Izmeneniya v nastoyashchey Politike konfidentsialnosti
        My ostavlyaem za soboy pravo obnovlyat yoki izmenyat nastoyashchuyu Politiku konfidentsialnosti v lyuboe vremya. Izmeneniya bo'ladi opublikovani na etoy stranitse s obnovlennoy datoy vstupleniya v silu. Pojaluysta, periodicheski prosmatrivayte etu politiku, chtoby byt v kurse togo, kak my zashchishchaem vashu informasiyu.
        
        9. Kontakt ma'lumotlari
        Agar siz qanday savol yoki opaseniya otnositelno etoy Politiki konfidentsialnosti yoki shaxsiy shaxsiy dannyx, svyajites s nami:
        UniverMondo
        Elektronnaya pochta: univermondo@gmail.com
        Veb-sayt: univermondo.com
        `
      ],
      Info7: [
          `
      Politika ispolzovaniya faylov cookie fayli
      Ma'lumotlar sanasi: 06.12.2024
      
      UniverMondo.com uluchsheniya vashego opyta prosmotra uchun cookie fayli va analogik texnologii ishlatadi, veb-saytlarni tahlil qilish va uluchsheniya nashix xizmatlarini ko'rsatadi. V etoy Politike foydalanish faylov cookie fayli mavjud, shuning uchun cookie fayli, qanday qilib mening katta ispolzuem va qanday qilib boshqarish mumkin.
      
      1. Chto takoe fayly cookie?
      Cookie fayli — bu nebolshie tekstovye fayl, kotorye razmeshyutsya va sizning qurilmangiz (kompyuter, smartfon, planshet) uchun veb-saytlar. Oni pomogayut veb-saytlari raspoznavat vashe ustroystvo va zapominat informatsiyu o vashem poseschenii, masalan, vashi predpochteniya yoki deystviya.
      
      2. Tipy faylov cookie, kotorye my ispolzuem
      UniverMondo.com saytida cookie fayl turini ishlataman:
      
      a. Osnovnye fayly cookie fayli
      Huquqiy ish veb-sayt uchun cookie fayli neobxodimy. Uni obespechivayut osnovnye funksiyalari, shuningdek qanday bezopasnost, upravlenie setyu va dostupnost.
      
      Misol: veb-sayt uchun sohranenie seansa vo vremya navigatsiya.
      b. Fayl cookie proizvoditelnosti va analitiki
      Eti fayli cookie ma'lumotnomasi o tom, kak posetiteli ispolzuyut Nash veb-sayt, masalan, posesshennye stranitsy va vremya, provedennoe na sayte. Eti dannye pomogayut nam uluchshit proizvoditelnost veb-sayt va polzovatelskiy opyt.
      
      Misol: Google Analytics yoki analogli asboblar.
      c. Funksional fayl cookie fayli
      Eti fayli cookie fayli veb-saytni zapominat sdelannyy vami vybor (masalan, yazykovye predpochteniya) va predostavlyat rasshirennye funksiyalarini buzadi.
      
      Misol: sohranenie predpochitaemogo vami yazyka uchun budushchix poseshcheniy.
      d. reklama fayli cookie fayli
      Pokaza relevantnoy reklama uchun bu faylni cookie-faylini ishlatish mumkin. Oni ham mumkin ogranichivat kolichestvo pokazov reklama va pomogat ocenivat effektivnost reklama kampaniyasi.
      
      Misol: retargeting reklama na platformax ijtimoiy setey.
      3. Kak my ispolzuem fayly cookie fayli
      Cookie faylini ishlataman, bu:
      
      Obespechit pravilnuyu va bezopasnuyu rabotu veb-sayt.
      
      Ponyat, qanday posetiteli vzaimodeystvuyut s nahim veb-sayt.
      Zapomnit vashi predpochteniya i uluchshit polzovatelskiy opyt.
      Dostavlyat personalizatsiyarovannyy kontent va reklama.
      4. Stronnie fayly cookie fayli
      Analitik, reklama yoki dori vositalari uchun vashem ustroystve uchun cookie faylini o'zgartirishingiz mumkin. U etix tretix lits est sobstvennye siyosati konfidentsialnosti va foydalanish faylov cookie, i my rekomenduem vam oznakomitsya s nimi.
      
      Misollar storonnyh faylov cookie, kotorye my mojem foydalanish mumkin:
      
      Google Analytics uchun tahlillar uchun veb-sayt.
      Platformy ijtimoiy setey uchun obmena va reklama.
      
      5. Upravlenie faylami cookie fayli
      Vy mojete nazoratirovat i upravlyat faylami cookie neskolkimi sposobami:
      
      a. Nastroyki brauzer
      Bolshinstvo veb-brauzerov pozvolyayut vam upravlyat faylami cookie yoki otklyuchat ix cherez svoi nastroyki. Vnimanie obratite, chto otklyuchenie faylov cookie-faylini veb-saytga qo'yish mumkin.
      
      Misol: ochistka faylov cookie yoki blokirovka opredelennyh tipov faylov cookie.
      
      b. Instrumenty otkaza
      Cookie-faylni o'rnatishingiz mumkin, bu stranitsi otkaza (masalan, Google Ads qurilmasi).
      
      c. Banner fayl cookie fayli
      Prince pervom poseschenii nashego veb-sayt vy mumkinmi qabul qilish yoki o'tklonit fayli cookie s pomoshchyu bannera sug'lasiya na ispolzovanie faylov cookie. Vashi nastroyki budut sohraneny uchun budushchix poseshcheniy.
      
      6. Obnovleniya etoy siyosati v otnoshenii faylov cookie
      My mojem vremya ot vremeni obnovlyat etu politiku v otnoshenii faylov cookie, chtoby otrajat izmeneniya on texnologiyax, pravila yoki nashey delovoy practice. Poslednyaya versiya vsegda budet dostupna na etoy stranitse, a data vsstupleniya v silu budet obnovlyatsya sootvetstvuyushchim obrazom.
      
      7. Svyajites s nami
      Agar siz qanday savol yoki opaseniya povodu ispolzovaniya nami faylov cookie fayli, svyajites s nami:
      UniverMondo
      Elektronnaya pochta: univermondo@gmail.com
      Veb-sayt: univermondo.com
      
      `
      ],
      Info8: [
        `
      Usloviya
      Ma'lumotlar sanasi: 06.12.2024
      
      UniverMondo.com saytida dobro pojalovat! Zaxodya na nash sayt i ispolzuya ego, vy soglashaetes s nastoyashhimi Usloviyami. Pojaluysta, vnimatelno prochtite ix pered ispolzovaniem nashego sayta.
      
      1. Vedenie
      UniverMondo.com — bu agentstvo, kotoroe pomogaet s:
      
      Italiya universitetida postupleniem.
      Rukovodstvom po podache zayavleniy na poluchenie vizy.
      Organizatsiey poezdok i logistikoy.
      Personalizatsiyarovannymi putevoditelyami po gorodu dlya studentov.
      Eti Usloviya reguliruyut ispolzovanie vami nashego sayta i uslug. Polzuyas nashimi uslugami, vy soglashaetes soblyudat bu usloviya.
      
      2. Pravo na uchastie
      Chtoby polzovatsya nashimi uslugami, vy doljny:
      
      Byt ne moloje 18 let ili imet soglasie roditelya/opekuna.
      Predostavlyat tochnuyu i pravdivuyu informatsiyu vo vsex formax va soobshcheniyax.
      Polzuyas nahim saytom, vy podtverjdaete, chto sootvetstvuete etim trebovaniyam.
      
      3. Predostavlyaemye uslugi
      UniverMondo vystupaet v kachestve posrednika, pomogayuschego studentam va putechestvennikam Italiyada. Xotya my stremimsya okazyvat tochnuyu va svoevremennuyu pomoshch, vy priznaete, chto:
      
      Zachislenie v universitety, odobrenie viz i organizatsiya poyezdok kafolat bermaydi.
      Lyubye zaderjki ili problemy, vyzvannye tretimi litsami (misol, universitetami, konsulstvami, turistik agentstvami), naxodyatsya vne nashego kontrolya.
      Dopolnitelnye rashody (misol, registratsionnye sbory, transportnye rashody, vizovye sbory) yavlyayutsya otvetstvennostyu polzovatelya.
      4. Obyazannosti polzovatelya
      Ispolzuya nashi uslugi, vy soglashaetes:
      
      Predostavlyat vse neobxodimye dokumenty va informatsiyu nezamedlitelno.
      Sledovat predostavlennym nami instruksiyam va rekomendatsiyam.
      Ispolzovat nashi uslugi tolko v zakonnyx tslyax.
      Vam zapreshcheno:
      
      Iskajat svoyu lichnost ili namereniya.
      Ispolzovat nash veb-sayt ili uslugi uchun uchastiya v mosennicheskoy ili nezakonnoy deyatelnosti.
      5. Plateji va plateji
      Stoimost nashix usluget budjet soobshchena vam v protsesse konsultatsiya.
      
      Oplata doljna byt proizvedena v polnom ob'eme do nachala okazaniya xizmat ko'rsatish, agar inoe bo'lmasa kerak.
      Vozvrat sredstv nevozmojen posle nachala okazaniya uslug, za isklyucheniem sluchaev sboya obslujivaniya po vine UniverMondo.
      6. Ogranichenie otvetstvennosti
      UniverMondo ne neset otvetstvennosti za:
      
      Otkazy ili zaderjki so storony universitetov, vizovyx slujb yoki drugix tretix lits.
      Ubytki yoki neudobstva, vyzvannye sboyami v poezdke yoki nepredvidennymi obstoyatelstvami.
      Informatsii, predostavlennoy polzovatelyamidagi xatolar.
      Nasha obshchaya otvetstvennost ogranichena summoy, uplachennoy za konkretnuyu predostavlennuyu uslug'i.
      
      7. Intellektual sobstvennost
      Ves kontent UniverMondo.com, vklyuchaya tekst, grafiku, logotipi va dizayn, amalga oshirilgan sobstvennostyu UniverMondo va zashchishchen zakonami ob avtorskom prave i intellektualnoy sobstvennosti.
      
      Vy ne mojete vosproizvodit, rasprostranyat yoki ispolzovat nash kontent bez predvaritelnogo pismennogo siglasiya.
      
      8. Politika konfidentsialnosti
      Polzuyas nashimi uslugami, vy soglashaetes na sbor i ispolzovanie vashey lichnoy informatsii, ka ukazano v nashey Politike konfidentsialnosti.
      
      9. Prekrashchenie predostavleniya uslug
      My ostavlyaem za soboy pravo prekratit yoki priostanovit dostup k nasim uslugam, esli:
      
      Vy narushaete nastoyashchie Usloviya.
      Vy predostavlyaete lojnuyu yoki nepolnuyu informasiyu.
      V takix sluchayax uje uplachennye sbory ne podlejat vozvratu.
      
      10. Ssylki tretix lits
      Nash veb-sayt mumkin soderjat sylki na vneshnie web-sayty yoki slujby. UniverMondo ne neset otvetstvennosti za soderjanie, politiku konfidentsialnosti yoki usloviya etix storonnix saytov. Ispolzuyte et sylki na svoy strax i risk.
      
      11. Izmeneniya v Usloviyax i polojeniyax
      My mojem periodicheski obnovlyat nastoyashchie Usloviya va polojeniya. Izmeneniya bo'ladi nashr etoy stranitse s obnovlennoy datoy vstupleniya v silu. Vy nesete otvetstvennost uchun regulyarnyy prosmotr etix usloviy.
      
      12. Primenimoe pravo
      Nastoyashchie Usloviya i polojeniya reguliruyutsya qonunami [Ukajite yurisdiktsiyu, masalan, Italiya]. Lyubye spory, voznikaushchie v svyazi s etimi usloviyami, bo'ladi razreshatsya v sudax O'zbekiston.
      
      13. Kontakt ma'lumotlari
      Agar siz qanday savol yoki opaseniya povodu nastoyashchix Usloviy va polojeniy, svyajites s nami:
      UniverMondo
      Elektronnaya pochta: univermondo@gmail.com
      Veb-sayt: univermondo.com
      
      `
      ],
      faq1: {
        question: ['Italiya universitetiga hujjat topshirish uchun qanday hujjatlar kerak?'],
        answer: [
          'Talablar va hujjatlar to‘plami har bir dastur uchun alohida tanlanadi. Umumiy hujjatlar quyidagilardan iborat:',
          '1. Diplom va baholar ro‘yxati',
          '2. Attestat va baholar ro‘yxati',
          '3. Tavsiyanoma',
          '4. Rezyume',
          '5. Motivatsion xat',
          'Agar ma’lum bir yo‘nalish yoki dastur uchun kerakli hujjatlar va talablarni bilishingiz kerak bo‘lsa, maslahatga yozilishingiz mumkin. Maslahatdan so‘ng barcha zarur ma’lumotlarni o‘z ichiga olgan faylni olasiz.',
        ],
      },
      faq2: {
        question: ['Men Qozog‘iston/Tajikiston/Armanistondanman... Siz menga yordam bera olasizmi?'],
        answer: ['Ha, biz dunyoning istalgan burchagidagi talabalarga universitetga kirish va stipendiya olishda yordam beramiz.'],
      },
      faq3: {
        question: ['Siz bilan qanday hamkorlikni boshlash mumkin?'],
        answer: [
          'Saytimizda qiziqqan tarif bo‘yicha ariza qoldiring. Tez orada siz bilan bog‘lanib, tanishuv maslahatini belgilaymiz, bu orqali bir-birimizni tushunib olamiz va sizga yordam bera olamizmi, aniqlaymiz. Maslahatdan so‘ng, har ikki tomon majburiyatlarini belgilaydigan shartnoma imzolaymiz. Oldindan to‘lov amalga oshirilgandan so‘ng, biz ishni boshlaymiz.',
        ],
      },
      faq4: {
        question: ['Qanday yo‘nalishlarda bepul o‘qish mumkin?'],
        answer: [
          'Barcha davlat universitetlarida, dasturdan qat’i nazar, bepul o‘qish mumkin. Talabalar o‘qiydigan ba’zi dasturlar:',
          '- Fashion Studies bo‘yicha magistrlik dasturi;',
          '- Anglo-American Studies bo‘yicha magistrlik dasturi;',
          '- Turizm strategiyasi, madaniy strategiya va Made in Italy bo‘yicha magistrlik dasturi;',
          '- Kognitiv fanlar va muloqot nazariyasi bo‘yicha magistrlik dasturi;',
          '- Sustainable Building Engineering bo‘yicha bakalavrlik dasturi.',
          'Va boshqa ko‘plab dasturlar...',
        ],
      },
      faq5: {
        question: ['11-sinfdan so‘ng Italiya universitetiga kirish mumkinmi?'],
        answer: [
          'Afsuski, Italiyada bakalavrlik dasturiga kirish uchun 12 yillik akademik ta’lim talab qilinadi. Bir variant - Italiya universitetida foundation yiliga kirishdir. Ko‘pincha, bu yil imtiyozlar yoki grantlarni o‘z ichiga olmaydi, lekin tugatgandan so‘ng Italiya universitetiga kirib, stipendiya bilan bepul o‘qish mumkin. Biz foundation dasturiga kirishda ham yordam beramiz. Tafsilotlarni "foundation" yoki "foundation + VIP to‘liq qo‘llab-quvvatlash" tarif tavsifida topishingiz mumkin.',
        ],
      },
      faq6: {
        question: ['To‘liq bepul va stipendiya bilan o‘qish mumkinmi?'],
        answer: [
          'Ha, bu haqiqat. Italiyada ta’lim narxi oila daromadlariga qarab hisoblanadi. Agar daromadlar cheklovlardan oshmasa, talaba ta’lim to‘lovidan ozod qilinadi. Agar talaba mintaqaviy stipendiyani yutsa, u universitet to‘lovidan ozod qilinadi va 7 ming yevrogacha stipendiya oladi.',
        ],
      },
      faq7: {
        question: ['Ta’lim qaysi tilda olib boriladi?'],
        answer: [
          'Ta’lim ingliz yoki italyan tilida olib boriladi. Kirish uchun ingliz tilining talab darajasi har bir universitet va hatto dastur tomonidan alohida belgilanadi. O‘rtacha talab darajasi - B2.',
        ],
      },

      Stuff: [
        {
            job: 'Tashkilotchi',
            name: 'Baxrom Baxtiyarovich',
            image: "/images/Founder.jpg",
            desc: 'Men faqatgina boshlaganimda, o‘qishga kirish jarayoni menga juda qiyin tuyulgan edi: universitet tanlash, hujjatlarni rasmiylashtirish, mablag‘ topish. Lekin bu jarayondan o‘tib, mening vazifam - boshqalarga xorijda o‘qish orzularini amalga oshirishga yordam berish ekanini angladim. Shuning uchun men UniverMondo loyihasini yaratdim - bu dunyo bo‘ylab yetakchi universitetlarga kirish, stipendiya olish va yangi imkoniyatlarni ochishga yordam beradigan xizmat. Biz har bir talabga alohida yondashamiz, shunda siz har bir bosqichda nima qilishni aniq bilasiz va maqsadlaringizga erishasiz!',
        },
        {
            job: 'Bosh direktor',
            name: 'Anastasiya Sergeevna',
            image: "/images/SEO.jpg",
            desc: 'O‘zim shu yo‘ldan o‘tganimda, universitet tanlashdan tortib hujjatlarni tayyorlashgacha bo‘lgan barcha tafsilotlarni tushunish juda qiyin edi. Ammo bu tajriba menga xatolardan qanday qochish va maksimal natijalarga erishishni tushunish imkonini berdi. Endi men o‘z bilimlarimni UniverMondo orqali baham ko‘ryapman - bu xizmat abituriyentlarga dunyodagi eng yaxshi universitetlarga ishonch bilan kirishga yordam beradi. Biz birgalikda mos dasturni topamiz, hujjatlarni tayyorlaymiz va stipendiya olish imkoniyatingizni oshiramiz. Xorijda o‘qish faqat diplom emas, balki hayotning yangi bosqichidir. Birinchi qadamni tayyormisiz? Bepul maslahatga yoziling.',
        },
    ],  

  },
};

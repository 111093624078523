import { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../LanguageContext'
import { translations } from '../translations' // Import the translations




export default function Services() {
  

  const { language, setLanguage } = useLanguage();



  // Get the current translations based on selected language
  const currentTranslations = translations[language];

  const navigateToSection = (sectionId) => {
    setTimeout(() => {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        // Adjust scrolling to ensure proper alignment
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offset = -200; // Adjust this value for headers or spacing
        const offsetPosition = window.scrollY + elementPosition + offset;
  
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 200); // Delay to ensure the element is rendered
  };
  

  return (
<div className="relative bg-gradient-to-b from-[#1C3D33] via-[#1A302B] to-[#1C3D33] px-6 py-16 lg:py-24 lg:px-16 ">
  <div className="max-w-screen-xl mx-auto text-white">
    {/* Заголовок */}
    <div className="text-center mb-16">
      <h2 className="text-5xl font-extrabold text-[#E8DCC8] tracking-tight leading-tight sm:text-6xl">
        {currentTranslations.Services1}
      </h2>
      <p className="mt-6 text-lg text-gray-300 max-w-3xl mx-auto">
        {currentTranslations.Services2}
      </p>
    </div>

    {/* Карточки услуг */}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
      {[
        {
          title: currentTranslations.Services3,
          description: currentTranslations.Services4,
          icon: '🎓',
          bg: 'bg-gradient-to-br from-[#264A3F] to-[#213E36]',
          border: 'border-[#A8D9BB]',
          link: '/Admission',
        },
        {
          title: currentTranslations.Services5,
          description: currentTranslations.Services6,
          icon: '📄',
          bg: 'bg-gradient-to-br from-[#4E5031] to-[#3F422A]',
          border: 'border-[#D9D3A8]',
          link: '/Visa',
        },
        {
          title: currentTranslations.Services7,
          description: currentTranslations.Services8,
          icon: '📬',
          bg: 'bg-gradient-to-br from-[#313A4E] to-[#2A3343]',
          border: 'border-[#A8BBD9]',
          link: '/Contacts',
        },
        {
          title: currentTranslations.Services9,
          description: currentTranslations.Services10,
          icon: '✈️',
          bg: 'bg-gradient-to-br from-[#4E3136] to-[#3F2A2E]',
          border: 'border-[#D9A8AE]',
          link: '/Visa',
        },
      ].map((service, index) => (
        <div
          key={index}
          className={`relative p-8 rounded-2xl shadow-lg hover:shadow-2xl transform transition-transform duration-300 hover:scale-105 ${service.bg} border-2 ${service.border}`}
          onClick={() => {
            if (index === 2) {
              // Redirect to contacts page with hash for section
              window.location.href = `${service.link}#contacts-section`;
            } else {
              // Redirect for other items
              window.location.href = service.link;
            }
          }}
        >
          <div className="absolute -top-6 left-6 text-5xl text-[#E8DCC8]">{service.icon}</div>
          <h3 className="mt-8 text-2xl font-semibold text-[#E8DCC8] mb-4">
            {service.title}
          </h3>
          <p className="text-gray-300">{service.description}</p>
        </div>
      ))}
    </div>

    {/* Блок преимуществ */}
    <div className="mt-20">
      <h3 className="text-center text-4xl font-extrabold text-[#E8DCC8] mb-12">
        {currentTranslations.Services11}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        {[
          {
            title: currentTranslations.Services12,
            description: currentTranslations.Services13,
            icon: '✔️',
            link: '/Admission',
          },
          {
            title: currentTranslations.Services14,
            description: currentTranslations.Services15,
            icon: '⏳',
            link: '/Admission',
          },
          {
            title: currentTranslations.Services16,
            description: currentTranslations.Services17,
            icon: '🤝',
            link: '/Admission',
          },
          {
            title: currentTranslations.Services18,
            description: currentTranslations.Services19,
            icon: '🌍',
            link: '/',
          },
          {
            title: currentTranslations.Services20,
            description: currentTranslations.Services21,
            icon: '📞',
            link: '/Contacts',
          },
        ].map((reason, index) => (
            <div
              key={index}
              className="flex cursor-pointer items-center gap-6 p-8 bg-gradient-to-r from-[#213E36] via-[#1C3D33] to-[#264A3F] rounded-2xl shadow-xl hover:shadow-2xl transition-transform duration-300 transform hover:scale-105 border border-[#336659]/50"
              onClick={() => {
                if (index === 3) {
                  // Scroll to the spinner section for the 4th item
                  navigateToSection('spinner-section');
                } else {
                  // Redirect for other items
                  window.location.href = reason.link;
                }
              }}
            >
              <div className="text-4xl text-[#E8DCC8]">{reason.icon}</div>
              <div>
                <h4 className="text-xl font-semibold text-[#E8DCC8]">
                  {reason.title}
                </h4>
                <p className="text-gray-300">{reason.description}</p>
              </div>
            </div>
                    
        ))}
      </div>
    </div>
  </div>
</div>



  )
}

import { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations'; // Import the translations

const HeroSection = () => {
  const { language } = useLanguage();
  const currentTranslations = translations[language];

  return (
    <div className="relative w-full h-screen overflow-hidden pt-20 bg-black">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/rome.png)`,
          backgroundSize: 'cover',
          backgroundAttachment: 'scroll',
        }}
      ></div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Main Content */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-4">
        <h1 className="text-4xl md:text-6xl md:text-7xl font-bold mb-6 max-w-5xl">
          {currentTranslations.HeroSection1}
        </h1>
        <p className="text-lg md:text-2xl mb-8 max-w-xl">
          {currentTranslations.HeroSection2[0]}
          <span className="font-black text-3xl border-2 border-white rounded-xl mx-2 px-2">{currentTranslations.HeroSection2[1]}</span>
          {currentTranslations.HeroSection2[2]}
        </p>
        <button className="px-10 py-4 bg-white text-[#213E36] font-bold rounded-full transition duration-300 hover:bg-gray-300 shadow-lg" onClick={() => window.location.href = '/Contacts'}>
          {currentTranslations.HeroSection3}
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
